export const routePaths = {
  rootPage: '/',
  dashboard: '/dashboard',
  quizResults: '/quiz-results',
  courses: '/courses',
  mediaStorage: '/courses/media-storage',
  navigationItems: '/navigation-items',
  designLookAndFeel: '/design/look-and-feel',
  addNewCourse: '/courses/add-new',
  editCourse: '/courses/[course_id]',
  membersAndLeads: '/members-and-leads',
  memberEdit: '/members-and-leads/[id]',
  orders: '/orders',
  calendar: '/calendar',
  leaderboard: '/leaderboard',
  leaderboardStats: '/leaderboard-stats',
  optin: '/optin',
  addNewOptin: '/optin/new',
  editOptin: '/optin/[id]',
  shoutoutBox: '/shoutout-box',
  aiVideoCreation: '/ai-video-creation',
  accountMenu: '/account',
  helpCenter: '/help-center',
  settings: '/settings',
  moduleSettings: '/courses/[course_id]/modules/[module_id]',
  lessonSettings: '/courses/[course_id]/modules/[module_id]/lessons/[lesson_id]',
  settingsAnalytics: '/settings/analytics',
  settingsCustomerSupport: '/settings/customer-support',
  settingsGeneral: '/settings/general',
  settingsEmail: '/settings/email',
  settingsNotifications: '/settings/notifications',
  settingsPaymentProviders: '/settings/payment-providers',
  products: '/products',
  settingsLegalDocuments: '/settings/legal-documents',
  settingsCommunity: '/settings/community',
  integrationsAndAutomations: '/settings/integration',
  settingsAffiliateLinks: '/settings/affiliate-links',
  landingPage: '/landing-page',
  addNewLandingPage: '/landing-page/add-new',
  landingPageSettings: '/landing-page/[id]',
  appRequests: '/app-requests',
  appRequestsCC: '/app-requests',
  settingsProfile: '/settings/profile',
  appRequestsSettings: '/app-requests/[id]',
  notifications: '/notifications',
  community: '/community',
  communityPage: '/community/[id]',
  threadPage: '/community/thread/[id]',
  scheduledPosts: '/community/[id]/posts',
  contentCreators: '/content-creators',
  contentCreatorsDetails: '/content-creators/[id]',
  customerProfile: '/customer/profile',
  customerLeaderboard: '/customer/leaderboard',
  customerCalendar: '/customer/calendar',
  customerDashboard: '/customer/dashboard',
  customerCourseDetails: '/customer/[course_type]',
  addNewCommunity: '/community/add-new',
  loginPageDesign: '/login-page-design',
  academyCustomization: '/academy-customization',
  legalDoc: '/customer/legal/[legal_doc_type]'
}

// Role-based accessible paths
export const rolePaths = {
  //CC
  '1': [
    routePaths.membersAndLeads,
    routePaths.memberEdit,
    routePaths.navigationItems,
    routePaths.orders,
    routePaths.courses,
    routePaths.addNewCourse,
    routePaths.editCourse,
    routePaths.dashboard,
    routePaths.quizResults,
    routePaths.rootPage,
    routePaths.accountMenu,
    routePaths.helpCenter,
    routePaths.settings,
    routePaths.settingsCustomerSupport,
    routePaths.settingsGeneral,
    routePaths.settingsLegalDocuments,
    routePaths.settingsCommunity,
    routePaths.settingsEmail,
    routePaths.settingsNotifications,
    routePaths.settingsAffiliateLinks,
    routePaths.mediaStorage,
    routePaths.settingsAnalytics,
    routePaths.settingsPaymentProviders,
    routePaths.settingsProfile,
    routePaths.optin,
    routePaths.addNewOptin,
    routePaths.editOptin,
    routePaths.calendar,
    routePaths.leaderboard,
    routePaths.leaderboardStats,
    routePaths.shoutoutBox,
    routePaths.aiVideoCreation,
    routePaths.moduleSettings,
    routePaths.lessonSettings,
    routePaths.integrationsAndAutomations,
    routePaths.landingPage,
    routePaths.addNewLandingPage,
    routePaths.landingPageSettings,
    routePaths.designLookAndFeel,
    routePaths.academyCustomization,
    routePaths.notifications,
    routePaths.community,
    routePaths.communityPage,
    routePaths.threadPage,
    routePaths.scheduledPosts,
    routePaths.addNewCommunity,
    routePaths.loginPageDesign,
    routePaths.academyCustomization,
    routePaths.appRequests

    // ...Object.values(pathsDemo) // Add demo paths
  ],

  // Student
  '2': [
    routePaths.customerLeaderboard,
    routePaths.customerDashboard,
    routePaths.customerCourseDetails,
    routePaths.customerProfile,
    routePaths.notifications,
    routePaths.customerCalendar,
    routePaths.community,
    routePaths.communityPage,
    routePaths.threadPage,
    routePaths.scheduledPosts,
    routePaths.addNewCommunity,
    routePaths.legalDoc
  ],

  // Hidden Customer
  '4': [
    routePaths.customerDashboard,
    routePaths.customerCourseDetails,
    routePaths.customerProfile,
    routePaths.notifications,
    routePaths.community,
    routePaths.customerCalendar,
    routePaths.communityPage,
    routePaths.threadPage,
    routePaths.scheduledPosts,
    routePaths.addNewCommunity,
    routePaths.customerLeaderboard,
    routePaths.legalDoc
  ],

  //Root
  '5': [
    routePaths.courses,
    routePaths.addNewCourse,
    routePaths.editCourse,
    routePaths.mediaStorage,
    routePaths.rootPage,
    routePaths.settings,
    routePaths.settingsAnalytics,
    routePaths.settingsPaymentProviders,
    routePaths.products,
    routePaths.integrationsAndAutomations,
    routePaths.landingPage,
    routePaths.landingPageSettings,
    routePaths.appRequests,
    routePaths.appRequestsSettings,
    routePaths.contentCreators,
    routePaths.contentCreatorsDetails

    // ...Object.values(pathsDemo) // Add demo paths
  ],

  // guest
  guest: [routePaths.community, routePaths.communityPage]
}
