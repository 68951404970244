import { mergeQueryKeys } from '@lukemorales/query-key-factory'

import courses from './courses'
import modules from './modules'
import stats from './stats'
import images from './images'
import lessons from './lessons'
import files from './files'
import folders from './folders'
import quizzes from './quizzes'
import settings from './settings'
import paymentSettings from './payment-settings'
import optins from './optins'
import members from './members'
import emails from './emails'
import landings from './landings'
import orders from './orders'
import products from './products'
import integrationsAndAutomations from './integrations-automations'
import portal from './portal'
import languages from './languages'
import courseAccess from './course-access'
import appRequests from './appRequests'
import shoutoutBox from './shoutout-box'
import communities from './communities'
import topics from './topics'
import threads from './threads'
import quizStatistics from './quizStatistics'
import activityLog from './activity-log'
import customerCourses from './customer-courses'
import design from './design'
import notifications from './notifications'
import contentCreators from './content-creators'
import navigationItems from './navigationItems'
import loginPageDesign from './login-page-design'
import academyCustomization from './academy-customization'
import threadMessages from './thread-messages'
import threadMessagesReplies from './thread-messages-replies'
import publicTemplates from './templates'
import calendar from './calendar'
import leaderboards from './leaderboard'
import aiVideoCreation from './ai-video-creation'
import courseCategories from './course-categories'
import courseTemplates from './course-templates'
import moderators from './moderators'

const queries = mergeQueryKeys(
  courses,
  courseCategories,
  courseTemplates,
  modules,
  lessons,
  images,
  stats,
  files,
  quizzes,
  folders,
  optins,
  settings,
  paymentSettings,
  members,
  orders,
  emails,
  landings,
  publicTemplates,
  products,
  shoutoutBox,
  integrationsAndAutomations,
  calendar,
  portal,
  languages,
  courseAccess,
  appRequests,
  communities,
  topics,
  threads,
  threadMessages,
  threadMessagesReplies,
  moderators,
  appRequests,
  activityLog,
  customerCourses,
  design,
  notifications,
  navigationItems,
  loginPageDesign,
  contentCreators,
  quizStatistics,
  leaderboards,
  academyCustomization,
  aiVideoCreation
)

export default queries
