import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'

import queries from '../../../queries'
import settingsService, { GetCommunitySettingsRequest } from '../../../service/settings-service'
import { CommunityDisplayNames, CommunitySettings } from '../../../types/settings'

export type CommunitySettingsExtendedType = CommunitySettings & {
  display_names: CommunityDisplayNames
}

const communitySettingsParams: GetCommunitySettingsRequest = {
  columns: [
    'id',
    'is_active',
    'show_only_internally',
    'topics_creation_is_enabled',
    'threads_creation_is_enabled',
    'threads_likes_is_enabled',
    'display_name_setting_code',
    'thread_messages_likes_is_enabled',
    'threads_anonym_posting_is_enabled'
  ],
}

const useCommunitySettings = (isEnabled = true) => {

  const {
    data: communitySettingsData,
    isLoading: communitySettingsIsLoading,
    isError: communitySettingsIsError
  } = useQuery({
    ...queries.settings.communitySettings,
    queryFn: () => settingsService.getCommunitySettings(communitySettingsParams),
    select: response => response.data.result[0]
  })

  const {
    data: displayNamesData,
    isLoading: displayNamesIsLoading,
    isError: displayNamesIsError
  } = useQuery({
    ...queries.settings.communityDisplayNames,
    queryFn: () => settingsService.getCommunityDisplayNames(),
    select: response => response.data.result,
    enabled: isEnabled
  })

  const data = useMemo(() => {
    if (!communitySettingsData) return null

    const communitySettingsWithName: CommunitySettingsExtendedType = {
      ...communitySettingsData,
      display_names: displayNamesData || []
    }

    return communitySettingsWithName
  }, [communitySettingsData, displayNamesData])

  return {
    isLoading: communitySettingsIsLoading || displayNamesIsLoading,
    isError: communitySettingsIsError || displayNamesIsError,
    data
  }
}

export default useCommunitySettings
