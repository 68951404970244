import { ReactNode, useMemo } from 'react'

import { Theme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import Layout from 'src/@core/layouts/Layout'
import { useSettings } from 'src/@core/hooks/useSettings'

import { useCommunities } from 'src/hooks/community/useCommunities'
import { useAuth } from 'src/hooks/useAuth'
import useCommunitySettings from 'src/hooks/settings/community/useCommunitySettings'
import { usePortalSettings } from 'src/hooks/usePortalSettings'

import VerticalNavItems from 'src/navigation/vertical'
import HorizontalNavItems from 'src/navigation/horizontal'

import { UserRolesType } from 'src/utils/getUserRole'

import useNavigationItems from '../hooks/navigationItems/useNavigationItems'
import { DEFAULT_NAV_LINKS } from '../navigation/constants'
import { CustomNavNames } from '../navigation/types'
import { CustomerSupportScripts } from '../components/customer-support'
import { subjects } from '../navigation/vertical/subjects'
import { permissionActions } from '../configs/permissionActions'

import Can from './components/acl/Can'

interface Props {
  children: ReactNode
  contentHeightFixed?: boolean
}

const UserLayout = ({ children, contentHeightFixed }: Props) => {
  // ** Hooks
  const { settings, saveSettings } = useSettings()
  const { user } = useAuth()
  const { portalSettings } = usePortalSettings()

  const isCustomer = useMemo(
    () => user?.role === UserRolesType.CUSTOMER || user?.role === UserRolesType.HIDDEN_CUSTOMER,
    [user]
  )

  const isGuest = useMemo(
    () => user === null,
    [user]
  )

  const { data: communities, isLoading: isLoadingCommunities, isError: isErrorCommunities } = useCommunities()
  const {
    data: communitySettings,
    isLoading: isLoadingCommunitiesSettings,
    isError: isErrorCommunitiesSettings
  } = useCommunitySettings(!!user)

  const isLoading = useMemo(
    () => isLoadingCommunities || isLoadingCommunitiesSettings,
    [isLoadingCommunities, isLoadingCommunitiesSettings]
  )

  const showCommunities = useMemo(
    () =>
      !communitySettings?.is_active ||
      isErrorCommunities ||
      isErrorCommunitiesSettings ||
      (!communitySettings?.show_only_internally && isCustomer),
    [communitySettings, isErrorCommunities, isErrorCommunitiesSettings, isCustomer]
  )


  const { data: customLinks } = useNavigationItems(Boolean(isCustomer || isGuest));

  const { customNavNames, customNavItems } = useMemo(() => {
    if (!customLinks) return {}

    const namesPart = customLinks.slice(0, 4)

    const customNavNames: CustomNavNames = {
      leaderboard: namesPart?.find(el => el.link === DEFAULT_NAV_LINKS.leaderboard)?.name,
      calendar: namesPart?.find(el => el.link === DEFAULT_NAV_LINKS.calendar)?.name,
      dashboard: namesPart?.find(el => el.link === DEFAULT_NAV_LINKS.dashboard)?.name,
      community: namesPart?.find(el => el.link === DEFAULT_NAV_LINKS.community)?.name
    }

    const customNavItems = customLinks.slice(Object.values(customNavNames).filter(Boolean).length)

    return { customNavNames, customNavItems }
  }, [customLinks])

  const customerNavSettings = useMemo(() => {
    if (!portalSettings) return { showLeaderboard: false, showCalendar: false }

    return {
      showLeaderboard: portalSettings.general?.is_leader_board_shown ?? false,
      showCalendar: portalSettings.general.is_calendar_enabled ?? false
    }
  }, [portalSettings])

  // ** Vars for server side navigation
  // const { menuItems: verticalMenuItems } = ServerSideVerticalNavItems()
  // const { menuItems: horizontalMenuItems } = ServerSideHorizontalNavItems()

  /**
   *  The below variable will hide the current layout menu at given screen size.
   *  The menu will be accessible from the Hamburger icon only (Vertical overlay Menu).
   *  You can change the screen size from which you want to hide the current layout menu.
   *  Please refer useMediaQuery() hook: https://mui.com/material-ui/react-use-media-query/,
   *  to know more about what values can be passed to this hook.
   *  ! Do not change this value unless you know what you are doing. It can break the template.
   */
  const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))

  if (hidden && settings.layout === 'horizontal') {
    saveSettings({ ...settings, layout: 'vertical' })
  }

  return (
    <Layout
      hidden={hidden}
      settings={settings}
      saveSettings={saveSettings}
      contentHeightFixed={contentHeightFixed}
      verticalLayoutProps={{
        navMenu: {
          navItems: VerticalNavItems({
            communities,
            isLoadingCommunities: isLoading,
            showCommunities,
            isCustomer,
            ...customerNavSettings,
            customNavNames,
            customNavItems,
            isGuest: user === null,
          })

          // Uncomment the below line when using server-side menu in vertical layout and comment the above line
          // navItems: verticalMenuItems
        }
      }}
      {...(settings.layout === 'horizontal' && {
        horizontalLayoutProps: {
          navMenu: {
            navItems: HorizontalNavItems({
              communities,
              isLoadingCommunities: isLoading,
              showCommunities,
              isCustomer,
              ...customerNavSettings,
              customNavNames,
              customNavItems,
              isGuest: user === null
            })

            // Uncomment the below line when using server-side menu in horizontal layout and comment the above line
            // navItems: horizontalMenuItems
          }

          // appBar: {
          //   content: () => <HorizontalAppBarContent hidden={hidden} settings={settings} saveSettings={saveSettings} />
          // }
        },
        verticalLayoutProps: {
          navMenu: {
            navItems: VerticalNavItems({
              communities,
              isGuest: user === null,
              isLoadingCommunities: isLoading,
              showCommunities,
              isCustomer,
              ...customerNavSettings
            })

            // Uncomment the below line when using server-side menu in vertical layout and comment the above line
            // navItems: verticalMenuItems
          }
        }
      })}
    >
      {children}
      <Can a={subjects.stundentPage} I={permissionActions.read}>
        <CustomerSupportScripts />
      </Can>
    </Layout>
  )
}

export default UserLayout
