import Router from 'next/router'

import axios from 'axios'

import authConfig from 'src/configs/auth'

import env from 'src/envs'

import { requestUrls } from '../configs/url'

import authService from './auth-service'

const service = axios.create({
  baseURL: env.apiUrl
})

// these requests return 403 but shouldn't
export const skipLogoutUrls = [requestUrls.updatePassword, requestUrls.addSender]

service.interceptors.request.use(request => {
  request.withCredentials = true
  request.url = service.getUri({ url: request.url })

  const customToken =
    typeof request.headers?.Authorization === 'string' ? request.headers.Authorization.replace('Bearer ', '') : null

  const userToken = customToken || request.data?.token || authService.getUserToken()

  if (userToken) {
    request.headers.Authorization = `Bearer ${userToken}`
  }

  if (!request.url.includes('upload') && request.method?.toLowerCase() !== 'get') {
    request.data = { ...request.data, token: userToken }
  }

  if (request.url.endsWith('/file/upload')) {
    request.params = {
      token: userToken
    }
  }

  return request
})

service.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      window.location.reload()
    }

    if (
      window.localStorage.getItem(authConfig.storageTokenKeyName) &&
      error.response.status === 403 &&
      !skipLogoutUrls.some(skipUrl => error.config.url.includes(skipUrl))
    ) {
      window.localStorage.removeItem(authConfig.mentorToolsUserObjectKeyName)
      window.localStorage.removeItem(authConfig.storageTokenKeyName)
      window.localStorage.removeItem(authConfig.portalSettingsKeyName)

      Router.push('/login')
    }

    return Promise.reject(error)
  }
)

export default service
