import { ChangeEvent, DragEvent, DragEventHandler, FC, memo, useCallback, useEffect, useRef, useState } from 'react'

import { Button, LinearProgress, Stack, Typography, useTheme } from '@mui/material'

import { useTranslation } from 'react-i18next'
import Player from '@vimeo/player'
import DOMPurify from 'dompurify'
import { Controller } from 'react-hook-form'

import Icon from 'src/@core/components/icon'

import { useSelectedMedia } from 'src/contexts/SelectedMediaContext'

import { useMediaStorage } from 'src/hooks/useMediaStorage'
import useToast from 'src/hooks/useToast'
import { useVideoUpload } from 'src/hooks/files/useVideoUpload'
import { matchVideoUrlSource, useVideoUrlUploadForm } from 'src/hooks/files/useVideoUrlUploadForm'
import { useAuth } from 'src/hooks/useAuth'

import Can from 'src/layouts/components/acl/Can'
import { subjects } from 'src/navigation/vertical/subjects'

import { isFileType } from 'src/utils/files/isFileType'

import { permissionActions } from 'src/configs/permissionActions'

import authService from 'src/service/auth-service'
import fileService from 'src/service/file-service'
import { videoService } from 'src/service/video-service'

import MentorcloudModal from '../../modals/MentorcloudModal'
import InputGroup from '../../inputs/InputWithButtons'

import {
  DeleteButton,
  VideoUploaderSectionBox,
  UploaderWrapper,
  VideoUploaderDragDropContentTextsBox,
  Footer
} from './styled'

interface VimeoPlayerProps {
  videoId: string
}

const VimeoPlayer: FC<VimeoPlayerProps> = ({ videoId }: VimeoPlayerProps) => {
  const playerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (playerRef.current) {
      const player = new Player(playerRef.current, {
        id: parseInt(videoId),
        width: 0,
        autoplay: false
      })

      player.on('loaded', () => {
        const iframe = playerRef.current?.querySelector('iframe')
        if (iframe) {
          iframe.style.width = '100%'
          iframe.style.border = 'none'
        }
      })

      return () => {
        player.destroy()
      }
    }
  }, [videoId])

  return <div ref={playerRef}></div>
}

type VideoUploaderProps = {
  hasVideoUrlField?: boolean
  accept?: string
  existingVideoId?: string | null
  existingSource?: string | null
  requirements?: string
  formSetterFn?: (video: { id?: string; url?: string }) => void
  setUploadingState?: (state: boolean) => void
  setConvertingState?: (state: boolean) => void
  onUpload?: (vimeoId: string) => void
  mentorCloudEnabled?: boolean
}

const VideoUploader = ({
  hasVideoUrlField = true,
  accept = 'video/*',
  existingVideoId,
  existingSource,
  requirements,
  formSetterFn,
  setUploadingState,
  setConvertingState,
  onUpload,
  mentorCloudEnabled = true
}: VideoUploaderProps) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { notify } = useToast()

  const { user } = useAuth()

  const [isDragOver, setIsDragOver] = useState(false)
  const fileInput = useRef<HTMLInputElement | null>(null)

  const [videoId, setVideoId] = useState<string>()
  const [embedUrl, setEmbedUrl] = useState<string>()

  const { control, handleSubmit } = useVideoUrlUploadForm({
    url: existingSource || ''
  })

  const [isFromCloud, setIsFromCloud] = useState<boolean>(false)
  const { isMentorCloudOpen, closeMentorCloud, openMentorCloud } = useMediaStorage({ mediaType: 'video' })

  const { isSelected: selectedVideoFromMentorCloud } = useSelectedMedia()

  const onDelete = () => {
    if (isFromCloud) setIsFromCloud(false)

    fileInput.current && (fileInput.current.value = '')
    formSetterFn?.({ id: '', url: '' })
    notify('VIDEO.delete', { type: 'success' })
  }

  const { uploadVideo, progress, isConverting, isUploading, isPending: isVideoUploadPending } = useVideoUpload()

  const upload = async (file: File) => {
    if (!file) {
      return
    }
    if (!file.type.includes('video')) {
      notify('FILE_TYPE.video', { type: 'error' })

      return
    }

    const formData = new FormData()
    formData.append('payload', file)
    formData.append('name', file.name)
    const token = authService.getUserToken()
    if (token) {
      formData.append('token', token)
    }

    const source = await uploadVideo(formData)
    const {
      data: { result }
    } = await videoService.getVimeoId({
      filter_by: [
        {
          attribute: 'file_id',
          operator: '==',
          value: source
        }
      ]
    })

    const video = result[0]

    if (!onUpload) setVideoId(video.vimeo_id)
    formSetterFn?.({ id: video.file_id, url: fileService.getImageSource(video.file_id, user!.token!) })
    onUpload?.(video.vimeo_id)
  }

  const onDeleteClick = async () => {
    if (embedUrl) {
      setEmbedUrl('')
    } else if (videoId) {
      setVideoId('')
    }

    onDelete()
  }

  const onExistingVideoIdChange = useCallback(async () => {
    if (!existingVideoId) {
      if (existingSource) {
        setEmbedUrl(existingSource)
      }

      return
    }

    try {
      const {
        data: { result }
      } = await videoService.getVimeoId({
        filter_by: [
          {
            attribute: 'file_id',
            value: existingVideoId
          }
        ]
      })
      const video = result[0]

      setVideoId(video.vimeo_id)
    } catch (error) {}
  }, [existingSource, existingVideoId])

  useEffect(() => {
    onExistingVideoIdChange()
  }, [existingVideoId, onExistingVideoIdChange])

  const onDrag = useCallback(
    (isDragOver: boolean): DragEventHandler<HTMLDivElement> =>
      e => {
        e.preventDefault()
        setIsDragOver(isDragOver)
      },
    [setIsDragOver]
  )

  const onDrop = (e: DragEvent<HTMLDivElement>) => {
    onDrag(false)

    upload(e.dataTransfer.files[0])
  }

  const onMentorCloudVideoPick = useCallback(async () => {
    if (isFileType(selectedVideoFromMentorCloud)) {
      try {
        const {
          data: { result }
        } = await videoService.getVimeoId({
          filter_by: [
            {
              attribute: 'file_id',
              value: selectedVideoFromMentorCloud.file_id!
            }
          ]
        })
        const video = result.find(video => video.file_id === selectedVideoFromMentorCloud.file_id)!

        setVideoId(video.vimeo_id)
        formSetterFn?.({ id: selectedVideoFromMentorCloud.file_id, url: '' })
        setIsFromCloud(true)
      } catch (error) {
      } finally {
        closeMentorCloud()
      }
    }
  }, [closeMentorCloud, formSetterFn, selectedVideoFromMentorCloud])

  const onUploadByEmbedUrl = handleSubmit(async formData => {
    try {
      const url = await matchVideoUrlSource(formData.url)
      if (!url) return

      setEmbedUrl(DOMPurify.sanitize(url || ''))
      formSetterFn?.({ id: '', url })
    } catch (error) {
      notify('VIDEO.upload', { type: 'error' })
    }
  })

  const onSelectFile = () => fileInput.current?.click()
  const onChangeFile = (e: ChangeEvent<HTMLInputElement>) => e.target.files && upload(e.target.files[0])

  useEffect(() => {
    setUploadingState?.(isUploading)
    setConvertingState?.(isConverting)
  }, [isUploading, isConverting, setUploadingState, setConvertingState])

  return (
    <Stack gap={theme.spacing(4)}>
      <VideoUploaderSectionBox
        borderColor={`${isDragOver ? theme.palette.primary.main : theme.palette.divider}`}
        onDrop={onDrop}
        onDragLeave={onDrag(false)}
        onDragOver={onDrag(true)}
      >
        <MentorcloudModal
          mediaType='video'
          key={5}
          isOpen={!!isMentorCloudOpen}
          onClose={closeMentorCloud}
          onCancel={closeMentorCloud}
          onChoose={onMentorCloudVideoPick}
        />
        <input
          accept={accept}
          type='file'
          id='file'
          ref={fileInput}
          style={{ display: 'none' }}
          onChange={onChangeFile}
        />
        {(embedUrl || videoId) && (
          <Stack width={'100%'} position={'relative'}>
            <DeleteButton color='error' onClick={onDeleteClick}>
              <Icon fontSize={theme.typography.h5.fontSize} icon={'tabler:trash'} />
            </DeleteButton>
            {videoId && <VimeoPlayer videoId={videoId} />}
            {embedUrl && (
              <iframe
                src={embedUrl}
                height={315}
                style={{ maxHeight: 315, width: '100%' }}
                frameBorder={0}
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
              />
            )}
          </Stack>
        )}
        {isUploading && (
          <UploaderWrapper>
            <Stack alignItems='center' justifyContent='center' gap={theme.spacing(4)}>
              <Stack alignItems='center' justifyContent='center' gap={theme.spacing(2)}>
                <Typography variant='subtitle1' color={theme.palette.primary.main}>
                  {t('ACTION.ENTITY.uploading_video', {
                    entity: fileInput.current?.files && fileInput.current?.files[0]?.name
                  })}
                </Typography>
                <Typography variant='caption' color={theme.palette.primary.main}>
                  {t('ACTION.do_not_close_tab')}
                </Typography>
              </Stack>
              <LinearProgress variant='determinate' value={progress} sx={{ width: '100%' }} />
              <Typography color={theme.palette.primary.main} variant='subtitle1'>
                {progress?.toFixed(0)}%
              </Typography>
            </Stack>
          </UploaderWrapper>
        )}
        {isConverting && (
          <UploaderWrapper>
            <Stack alignItems='center' justifyContent='center' gap={theme.spacing(4)}>
              <Stack alignItems='center' justifyContent='center'>
                <Typography variant='subtitle1' color={theme.palette.primary.main}>
                  {t('UPLOAD.VIDEO.convert')} {fileInput.current?.files && fileInput.current?.files[0]?.name}...
                </Typography>
              </Stack>
            </Stack>
          </UploaderWrapper>
        )}
        {!embedUrl && !videoId && !isVideoUploadPending && (
          <Stack alignItems='center' justifyContent='center' gap={theme.spacing(3)} padding={theme.spacing(10, 6)}>
            <VideoUploaderDragDropContentTextsBox>
              <Icon strokeWidth={0.5} icon='tabler:video' fontSize={theme.typography.h3.fontSize}></Icon>
            </VideoUploaderDragDropContentTextsBox>
            <Typography fontWeight={400} variant='h5'>
              {t('ACTION.ENTITY.drag_drop_video')}
            </Typography>
            <Typography color={theme.palette.text.disabled} variant='body1'>
              {t('MISC.or')}
            </Typography>
            <Footer>
              <Button onClick={onSelectFile} variant='contained'>
                {t('ACTION.browse_typed_files', { file_type: t('FILE_TYPE.video') })}
              </Button>
              {mentorCloudEnabled && (
                <Can I={permissionActions.manage} a={subjects.mentorCloud}>
                  <Button variant='outlined' startIcon={<Icon icon='tabler:cloud'></Icon>} onClick={openMentorCloud}>
                    {t('ENTITY.mentor_cloud')}
                  </Button>
                </Can>
              )}
            </Footer>
            <Typography textAlign={'center'} color={theme.palette.text.disabled} variant='caption'>
              {requirements ? requirements : t('PAGE.UPLOADER.video_req')}
            </Typography>
          </Stack>
        )}
      </VideoUploaderSectionBox>

      {hasVideoUrlField && (
        <Can I={permissionActions.manage} a={subjects.mentorCloud}>
          <Controller
            name='url'
            control={control}
            rules={{ required: true }}
            render={({ field: { value, onChange }, formState: { errors } }) => (
              <InputGroup
                required
                label={t('UPLOAD.VIDEO.video_url')}
                value={value}
                placeholder={t('UPLOAD.VIDEO.video_url_placeholder')}
                onChange={onChange}
                error={Boolean(errors.url)}
                disabled={!!videoId || isVideoUploadPending}
                buttonProps={{
                  children: t('UPLOAD.VIDEO.save_url'),
                  disabled: !!videoId || isVideoUploadPending,
                  onClick: onUploadByEmbedUrl
                }}
              />
            )}
          />
        </Can>
      )}
    </Stack>
  )
}

export default memo(VideoUploader)
