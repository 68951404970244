import { ValueOf } from '../common/utils'

export interface PortalMemberSettings {
  portal_member: null
  general: GeneralSettings
  design_settings: DesignSettings
  affiliate_product_page_settings: AffiliateProductPageSetting[]
  community_settings: CommunitySetting[]
  configuration: ConfigurationSetting[]
  content_design_settings: ContentDesignSetting[]
  content_email_settings: ContentEmailSetting[]
  content_homepage_settings: ContentHomepageSetting[]
  content_login_page_settings: ContentLoginPageSetting[]
  content_settings_selected: ContentSettingsSelected[]
  customer_course_list_design_settings: CustomerCourseListDesignSetting[]
  customer_lesson_page_design_settings: CustomerLessonPageDesignSetting[]
  general_design_settings: GeneralDesignSetting[]
  portal_affiliate: PortalAffiliate[]
  portal_api_key: PortalApiKey[]
  portal_contact_info: PortalContactInfo[]
  portal_design_images: PortalDesignImage[]
  portal_to_design_settings: PortalToDesignSetting[]
  portal_to_marketplace: PortalToMarketplace[]
  settings_analytics: SettingsAnalytics[]
  settings_imprint_gdrp: SettingsImprintGdrp[]
  settings_payment: SettingsPayment[]
  settings_smtp: SettingsSmtp[]
  settings_welcome_email: SettingsWelcomeEmail[]
  support_settings: SupportSetting[]
  welcome_box_settings: WelcomeBoxSetting[]
}

export interface GeneralSettings {
  custom_domain: null
  custom_domain_enabled: boolean
  hostname: string
  language_id: number
  is_calendar_enabled: boolean
  is_leader_board_shown: boolean
}

export interface DesignSettings {
  id: number
  properties: DesignProperties
  title: string
  code_id: number
  type_id: number
}

interface UpdateAppThemeColors {
  appBackground: string
  backdropText: string
  container: string
  action: string
  actionButtonText: string
  regularText: string
  contrast: string
  navigationBackground: string
  navigationText: string
  bigHeadingText: string
}

export interface UpdateAppTheme {
  colors_light: UpdateAppThemeColors
  colors_dark: UpdateAppThemeColors
  automatically_manage_dark_mode: boolean
}

export interface DesignProperties {
  favicon: string
  big_logo: string
  link_color: string
  small_logo: string
  react_box_bg_color: string
  react_nav_bg_color: string
  react_main_bg_color: string
  react_main_bg_contrast_color: string
  react_primary_color: string
  react_primary_contrast_color: string
  success_color: string
  main_text_font: string
  react_nav_text_color: string
  footer_bg_color: string
  footer_bg_image: string
  react_main_text_color: string
  main_text_style: string | boolean
  react_secondary_color: string
  box_border_color: string
  main_text_weight: string | boolean
  default_cml_cover: DefaultCmlCover
  primary_btn_color: string
  welcome_box_media: Media
  flat_primary_color: string
  headline_text_font: string
  shoutout_box_media: null
  headline_text_color: string
  headline_text_style: string | boolean
  secondary_text_font: string
  headline_text_weight: string | boolean
  main_text_decoration: string | boolean
  react_secondary_text_color: string
  secondary_text_style: string | boolean
  success_btn_bg_color: string
  welcome_box_bg_color: string
  welcome_box_bg_image: string
  use_gradient_headline: string | boolean
  second_gradient_color: string
  secondary_text_weight: string | boolean
  subheadline_text_font: string
  subheadline_text_color: string
  subheadline_text_style: string | boolean
  success_btn_text_color: string
  footer_additional_image: string
  subheadline_text_weight: string | boolean
  headline_text_decoration: string | boolean
  secondary_text_decoration: string | boolean
  default_shoutout_box_image: string
  wallpaper_login_page_image: string
  subheadline_text_decoration: string | boolean
}

export interface DefaultCmlCover {
  quiz: string
  course: string
  lesson: string
  module: string
}

export interface Media {
  type: string
  file_id: string
}

export interface AffiliateProductPageSetting {
  payload: string
  portal_id: number
}

export interface CommunitySetting {
  element_settings: any[]
  display_name_setting_code: string
  id: number
  threads_likes_is_enabled: boolean
  affiliate_widget_image: null
  threads_anonym_posting_is_enabled: boolean
  cover_id: null
  is_active: boolean
  show_only_internally: boolean
  portal_id: number
  topics_creation_is_enabled: boolean
  show_feed_view: boolean
  show_cover: boolean
  threads_creation_is_enabled: boolean
  thread_messages_likes_is_enabled: boolean
}

export const RegistrationType = {
  REGISTER_SINGLE: 1,
  REGISTER_DOUBLE: 2
} as const

export type RegistrationTypeId = ValueOf<typeof RegistrationType>

export interface ConfigurationSetting {
  allowed_register: boolean
  hide_unavailable: boolean
  require_tos_approval: boolean
  registration_type: RegistrationTypeId
  id: number
  show_expired_date: boolean
  portal_id: number
}

export interface ContentDesignSetting {
  default_user_avatar: null
  show_customer_shadows: boolean
  show_lesson_cover: boolean
  wallpaper_login_and_registration_mobile: string
  use_favicon_as_loader: boolean
  show_customer_header: boolean
  big_logo: null
  portal_id: number
  social_media_image: string
  small_logo: null
  default_cover_modules: null
  created_at_epoch: number
  default_cover_lessons: null
  default_cover_quizzes: null
  id: number
  big_logo_hide: boolean
  show_additional_footer_link: boolean
  favicon: null
  loader_color: string
  footer_additional_image: null
  default_cover_courses: null
  pages_title: string
  wallpaper_register_page: null
  wallpaper_login_page: null
  show_progress_bars: boolean
}

export interface ContentEmailSetting {
  email_course_double_optin_confirmation_subject: string
  email_existing_buyer_subject: string
  email_recently_registered_user_subject: string
  email_course_double_optin_welcome_subject: string
  user_password_reset_body: string
  email_new_user_body: string
  email_course_double_optin_welcome_body: string
  email_course_optin_welcome_body: string
  portal_id: number
  name: string
  email_course_optin_welcome_subject: string
  created_at_epoch: number
  email_registration_confirmation_subject: string
  email_course_double_optin_confirmation_body: string
  id: number
  email_new_user_subject: string
  email_existing_buyer_body: string
  user_password_reset_subject: string
  email_registration_confirmation_body: string
  contact_info_footer: string
  email_recently_registered_user_body: string
}

type ShoutoutBoxMediaType = {
  link?: string | null
  id: number
  content_homepage_settings_id: number
  file_id: string | null
  title: string | null
  type: string
}

export interface ContentHomepageSetting {
  video_files: ShoutoutBoxMediaType[]
  dashboard_footer_paragraph_text: string
  subtitle: string
  free_course_title: string
  shoutout_box_image: null
  allow_hide_shoutout_box: boolean
  show_shoutout_box: boolean
  portal_id: number
  dashboard_footer_headline_text: string
  course_custom_title_enabled: boolean
  name: string
  created_at_epoch: number
  id: number
  other_course_title: string
  paid_course_title: string
  shoutout_box_text: string
  is_course_categorise_on: boolean
  title: string
  description: string
  shoutout_box_avatar?: string
}

export interface ContentLoginPageSetting {
  show_title: boolean
  registration_title: null
  subtitle: string
  show_registration_title: boolean
  show_description: boolean
  show_registration_description: boolean
  portal_id: number
  created_at_epoch: number
  id: number
  use_subtitle_color: boolean
  show_subtitle: boolean
  registration_description: string
  subtitle_color: string
  show_dashboard_login: boolean
  title: string
  description: string
}

export interface ContentSettingsSelected {
  content_design_settings_id: number
  content_homepage_settings_id: number
  content_email_settings_id: number
  content_login_page_settings_id: number
  portal_id: number
}

export const CourseViewType = {
  LIST: 1,
  BOXES: 2,
  MIXED: 3
} as const

export type CourseViewTypeId = ValueOf<typeof CourseViewType>

export interface CustomerCourseListDesignSetting {
  course_view_type: CourseViewTypeId
  id: number
  other_course_title: string
  free_course_title: string
  paid_course_title: string
  is_course_categorise_on: boolean
  portal_id: number
  course_custom_title_enabled: boolean
}

export interface CustomerLessonPageDesignSetting {
  id: number
  show_covers_in_table: boolean
  show_numbers_in_table: boolean
  show_entity_name_in_table: boolean
  portal_id: number
  lesson_nav_on_right: boolean
  show_lesson_list: boolean
  show_cover: boolean
}

export interface GeneralDesignSetting {
  footer_settings: FooterSetting[]
  design_image: DesignImage[] | null
  id: number
  show_customer_shadows: boolean
  navigation_position: string
  use_favicon_as_loader: boolean
  loader_color: string
  shadows_size: string
  portal_id: number
  show_progress_bars: boolean
  browser_tab_title: string
}

export interface FooterCustomLink {
  id: number
  footer_design_settings_id: number
  open_new_tab: boolean
  name: string
  link: string
}

export interface FooterSetting {
  custom_links?: FooterCustomLink[]
  footer_text_color: string
  show_additional_image: boolean
  id: number
  show_footer_block: boolean
  additional_image: string | null
  background_color: string
  footer_headline: string
  general_design_settings_id: number
  background_image: string | null
  portal_id: number
  footer_desc: string
}

export interface DesignImage {
  default_cover_quizzes: string | null
  default_user_avatar: null
  id: number
  portal_id: number
  big_logo_hide: boolean
  favicon: string | null
  big_logo: string | null
  general_design_settings_id: number
  social_media_image: string | null
  default_cover_modules: string | null
  default_cover_courses: string | null
  default_cover_lessons: string | null
  small_logo: string | null
}

export interface PortalAffiliate {
  digistore_id: string
  portal_id: number
  one_click_affiliate_is_active: boolean
}

export interface PortalApiKey {
  api_key: string
  created_at: number
  id: number
  portal_id: number
}

export interface PortalContactAddress {
  street_and_number: string
  city: string
  company: string
  country: string
  postal_code: string
}

export interface PortalContactInfo {
  last_name: string
  first_name: string
  portal_id: number
  address: Partial<PortalContactAddress> | null
  site: string
  phone_number: string
  email: string
}

export interface PortalDesignImage {
  default_cover_quizzes: null
  default_user_avatar: null
  id: number
  portal_id: number
  big_logo_hide: boolean
  favicon: string | null
  big_logo: string
  general_design_settings_id: number
  social_media_image: string | null
  default_cover_modules: string | null
  default_cover_courses: string | null
  default_cover_lessons: string | null
  small_logo: string
}

export interface PortalToDesignSetting {
  id: number
  code_id: number
  is_active: boolean
  portal_id: number
  title: string
  design_id: number
  order: number
}

export interface PortalToMarketplace {
  marketplace_id: number
  id: number
  is_active: boolean
  portal_id: number
  name: string
  credentials: MarketplaceCredentials
}

export interface MarketplaceCredentials {
  api_key?: string
  ipn_url?: string
  secret_key?: string
  wait_for_payment?: boolean
  app_id?: string
  bundle_id?: string
  key_id?: string
  issuer_id?: string
  private_key?: string
  shared_secret?: string
  client_email?: string
}

export interface SettingsAnalytics {
  pixel_activity: null
  facebook_pixel_is_active: boolean
  portal_id: number
  google_analytics_id: null
  google_analytics_is_active: boolean
  facebook_pixel: null
}

export interface SettingsImprintGdrp {
  gdpr_banner_detail_text: null
  imprint_text: null
  gdrp_signature_file: null
  gdpr_banner_text: null
  portal_id: number
  privacy_policy_text: null
  terms_text: null
  tracking_condition_id: number
  gdpr_banner_about_text: null
  show_large_banner: boolean
  gdpr_banner_main_text: null
  show_small_banner: boolean
  gdpr_banner_accept_label: null
  large_banner_type_id: number
  gdpr_banner_reject_label: null
  show_terms_page: boolean
}

interface SettingsPayment {
  paypal_is_active: boolean
  digistore_is_active: boolean
  elopage_is_active: boolean
  clickbank_is_active: boolean
  portal_id: number
  own_email_templates_is_active: boolean
}

export interface SettingsSmtp {
  smtp_host: string | null
  portal_id: number
  type_of_encryption: string | null
  smtp_username: string | null
  smtp_password: string | null
  smtp_from_name: string | null
  smtp_authentication_is_available: boolean
  is_enabled: boolean
  smtp_port: string | null
  smtp_from_email: string | null
}

export interface SettingsWelcomeEmail {
  mailing_settings_type: number
  id: number
  portal_id: number
}

export interface SupportSetting {
  support_type_id: number
  id: number
  is_active: boolean
  portal_id: number
  credentials: {
    value: string
  }
}

export interface WelcomeBoxSetting {
  content_blocks: ContentBlock[]
  subheadline: string
  id: number
  bg_img: string
  headline: string
  bg_color: string
  portal_id: number
  show: boolean
  description: string
}

export interface Content {
  link?: string
  file_id: string
}

export interface ContentBlock {
  id: number
  welcome_box_settings_id: number
  content: Content
  type_id: number
  created_at: number
  is_active: boolean
  portal_id: number
  order: number
}

export interface ThemeColors {
  primary_color: string
  primary_contrast_color: string
  secondary_color: string
  main_bg_color: string
  main_bg_contrast_color: string
  box_bg_color: string
  main_text_color: string
  secondary_text_color: string
  nav_bg_color: string
  nav_text_color: string
}

export interface AppThemeColors {
  primary: string
  black: string
  danger: string
  dangerDisabled: string
  dangerTransparent16: string
  warning: string
  warningTransparent16: string
  firstPlaceLeaderBoard: string
  secondPlaceLeaderBoard: string
  thirdPlaceLeaderBoard: string
  whiteLeaderBoardPlaceText: string
  removeButtonBackground: string
  removeButtonIcon: string
  appBackground: string
  backdropText: string
  container: string
  containerShadow: string
  containerTransparent80: string
  action: string
  actionTransparent16: string
  actionTransparent8: string
  actionButtonText: string
  regularText: string
  regularTextTransparent16: string
  regularTextTransparent8: string
  regularTextTransparent4: string
  placeholderText: string
  mutedText: string
  contrast: string
  contrastTransparent16: string
  menuOpenWithTransparent6: string
  contrastTransparent8: string
  navigationBackground: string
  navigationText: string
  bigHeadingText: string
  regularHeadingText: string
  mediumHeadingText: string
  quizColorCorrect: string
  quizColorCorrectTransparent16: string
  quizColorIncorrect: string
  quizColorIncorrectTransparent16: string
}

export interface AppTheme {
  id: number
  colors_light: AppThemeColors
  colors_dark: AppThemeColors
  is_active: boolean
  name: string
  is_preset: boolean
  is_default: boolean
  order: number
  automatically_manage_dark_mode: boolean
}

export interface Theme {
  id: number
  automatically_manage_dark_mode: boolean
  colors_light: ThemeColors
  colors_dark: ThemeColors
  is_active: boolean
  name: string
  is_preset: boolean
  is_default: boolean
  primary_bold: boolean
  primary_font: string
  primary_italic: boolean
  primary_underline: boolean
  secondary_bold: boolean
  secondary_font: string
  secondary_italic: boolean
  secondary_underline: boolean
}

export interface PortalDesignBase {
  web: {
    theme_type: 'preset' | 'custom'
    theme_id: number
  }
  match_app_to_web_theme: boolean
}

export interface PortalDesignWithApp extends PortalDesignBase {
  app: {
    theme_type: 'preset' | 'custom'
    theme_id: number
  }
}
