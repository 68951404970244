import { useCallback, useEffect, useRef, useState } from 'react'

import { alpha, Stack, styled, useTheme } from '@mui/material'

import DOMPurify from 'dompurify'
import Player from '@vimeo/player'

import { matchVideoUrlSource } from 'src/hooks/files/useVideoUrlUploadForm'

import { videoService } from 'src/service/video-service'

interface VideoPlayerProps {
  fileId?: string | null
  link?: string | null
  width?: string | number
  maxWidth?: string | number
  height?: string | number
}

interface VimeoPlayerProps {
  videoId: string
  maxWidth?: string | number
  height?: string | number
}

const VideoWrapper = styled(Stack)(({ theme }) => ({
  borderRadius: theme.spacing(1.5), // Apply border radius
  overflow: 'hidden', // Clips the content inside
  position: 'relative'
}))

const VimeoPlayer = ({ videoId, maxWidth, height }: VimeoPlayerProps) => {
  const playerRef = useRef<HTMLDivElement | null>(null)
  const [paddingTop, setPaddingTop] = useState('56.25%')

  const initializePlayer = async () => {
    if (!playerRef.current) return

    const player = new Player(playerRef.current, {
      id: parseInt(videoId),
      width: 0,
      responsive: true,
      autoplay: false,
      height: typeof height === 'number' ? height : undefined
    })

    player.on('loaded', async () => {
      try {
        const width = await player.getVideoWidth()
        const height = await player.getVideoHeight()

        if (width && height) {
          const aspectRatio = (height / width) * 100
          setPaddingTop(`${aspectRatio}%`)
        }
      } catch (error) {
        console.error(error)
      }

      const iframe = playerRef.current?.querySelector('iframe')

      if (iframe) {
        Object.assign(iframe.style, {
          maxWidth: typeof maxWidth === 'number' ? `${maxWidth}px` : `${maxWidth}`,
          display: 'flex',
          justifyContent: 'center',
          alignSelf: 'center',
          margin: '0 auto',
          position: 'absolute',
          objectFit: 'cover',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          border: 'none',
          borderRadius: '6px',
          backgroundColor: 'black'
        })
      }
    })

    return player
  }

  useEffect(() => {
    let playerInstance: Player | undefined = undefined

    const loadPlayer = async () => {
      playerInstance = await initializePlayer()
    }

    loadPlayer()

    return () => {
      playerInstance?.destroy()
    }
  }, [videoId])

  return (
    <div
      style={{
        width: '100%',
        position: 'relative',
        paddingTop,
        borderRadius: '6px',
        overflow: 'hidden',
        backgroundColor: 'black'
      }}
    >
      <div
        ref={playerRef}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: height ? `${height}px` : '100%',
          backgroundColor: 'black'
        }}
      />
    </div>
  )
}

export const VideoPlayer = ({ fileId, link, width = '100%', height = '100%', maxWidth }: VideoPlayerProps) => {
  const theme = useTheme()
  const [videoId, setVideoId] = useState<string>()
  const [embedUrl, setEmbedUrl] = useState<string>()
  const [isLoading, setIsLoading] = useState<boolean>(!!fileId || !!link)

  const checkEmbedUrl = useCallback(async () => {
    if (!link) return

    setIsLoading(true)

    try {
      const url = await matchVideoUrlSource(link)
      if (url) setEmbedUrl(DOMPurify.sanitize(url || ''))
    } catch (error) {
      setIsLoading(false)
      console.error('Error fetching embed URL:', error)
    } finally {
      setIsLoading(false)
    }
  }, [link])

  const getVideo = useCallback(async () => {
    if (!fileId) return

    setIsLoading(true)

    try {
      const { data } = await videoService.getVimeoId({
        filter_by: [{ attribute: 'file_id', value: fileId }]
      })
      const video = data.result[0]
      if (video?.vimeo_id) setVideoId(video.vimeo_id)
    } catch (error) {
      setIsLoading(false)
      console.error('Error fetching Vimeo video:', error)
    } finally {
      setIsLoading(false)
    }
  }, [fileId])

  useEffect(() => {
    if (fileId) getVideo()
    if (link) checkEmbedUrl()
  }, [fileId, link, getVideo, checkEmbedUrl])

  return (
    <Stack>
      {isLoading ? (
        <VideoWrapper
          width={width}
          maxWidth={maxWidth}
          height={height}
          justifyContent='center'
          alignItems='center'
          bgcolor={alpha(theme.palette.secondary.main, 0.2)}
        />
      ) : (
        (embedUrl || videoId) && (
          <VideoWrapper width={width} maxWidth={maxWidth} position={'relative'}>
            {videoId && <VimeoPlayer height={height} maxWidth={maxWidth} videoId={videoId} />}

            {embedUrl && (
              <iframe
                src={embedUrl}
                height={height}
                width={width}
                style={{ width, height: height, maxWidth, borderRadius: theme.spacing(1.5), backgroundColor: 'black' }}
                frameBorder={0}
                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                allowFullScreen
                loading='lazy'
              />
            )}
          </VideoWrapper>
        )
      )}
    </Stack>
  )
}
