import { useMemo } from 'react'

import Script from 'next/script'

import { usePortalSettings } from '../../hooks/usePortalSettings'
import { useAuth } from '../../hooks/useAuth'
import { languageMap } from '../../context/constants'
import { supportTypeIDs } from '../../types/settings'

export const CustomerSupportScripts = () => {
  const { user } = useAuth()
  const { portalSettings } = usePortalSettings()

  const activeSupport = useMemo(() => {
    if (!portalSettings?.support_settings) return undefined

    return portalSettings.support_settings.find(support => support?.is_active)
  }, [portalSettings?.support_settings])

  const langCode = useMemo(() => {
    return user?.portal.language_id ? languageMap[user?.portal.language_id] : 'en'
  }, [user?.portal.language_id])

  if (!activeSupport) return null

  if (activeSupport.support_type_id === supportTypeIDs.freshdesk)
    return (
      <>
        <Script id='freshdesk-widget' async defer>
          {`
           window.fwSettings = {
             'widget_id': ${activeSupport.credentials.value},
             'locale': 'en'
           };
           !function() {
             if (typeof window.FreshworksWidget !== "function") {
               var n = function() {
                 n.q.push(arguments);
               };
               n.q = [];
               window.FreshworksWidget = n;
             }
           }();
         `}
        </Script>
        <Script
          type='text/javascript'
          src={`https://widget.freshworks.com/widgets/${activeSupport.credentials.value}.js`}
          async
          defer
        />
      </>
    )

  if (activeSupport.support_type_id === supportTypeIDs.intercom)
    return (
      <Script id='intercom-widget'>
        {`
          (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${activeSupport.credentials.value}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
          window.Intercom("boot", {
            api_base: "https://api-iam.intercom.io",
            app_id: "${activeSupport.credentials.value}",
          });
          window.Intercom('update', { language_override: '${langCode}' });
        `}
      </Script>
    )

  if (activeSupport.support_type_id === supportTypeIDs.helpscout)
    return (
      <Script id='helpscout-widget' type='text/javascript' async defer>
        {`
          !function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});
          window.Beacon('init', '${activeSupport.credentials.value}')
          `}
      </Script>
    )

  if (activeSupport.support_type_id === supportTypeIDs.membershipio)
    return (
      <Script id='membership-widget' type='text/javascript' async defer>
        {`
          (function(w,d,s,i,u,a) {
            w._searchie = {
              widget:i,
              url:u,
              user:a
            };
            var f=d.getElementsByTagName(s)[0],j=d.createElement(s);
            j.async = true;
            j.src='https://app.membership.io/js/js-popup.js?' + Date.now();
            f.parentNode.insertBefore(j,f);
          })(window, document, 'script', '${activeSupport.credentials.value}', 'https://app.membership.io');
        `}
      </Script>
    )

  return null
}
