import authService from 'src/service/auth-service'

import { usePortalType } from './usePortalType'

export const useUserPortalInfo = () => {
  const userInfo = authService.getUser()
  const { data: portalTypes } = usePortalType()

  const userPortalType = portalTypes?.find(type => type.id === userInfo?.portal?.type_id)?.name
  const isTrialEnded = userPortalType === 'trial_ended'
  const isTrial = userPortalType === 'trial'
  const isVerified =
    userInfo?.portal_member?.verification &&
    !userInfo?.portal_member?.verification?.verified_at &&
    userInfo.portal_member?.role_id !== 5

  return { isTrialEnded, isTrial, isVerified }
}
