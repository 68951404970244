import { useCallback, useRef } from 'react'

import { useInfiniteQuery } from '@tanstack/react-query'

import queries from 'src/queries'
import mediaStorageService from 'src/service/media-storage-service'

import { OrderConfig } from 'src/types/courses'
import { FileType } from 'src/types/files/fileData'
import { FolderType } from 'src/types/files/folder'
import { extensions, GetMediaRequest, MediaVariant } from 'src/types/files/media'
import { FilterConfig, FilterParams } from 'src/types/common/types'

type UseMediaViewProps = {
  mediaType?: MediaVariant
  folderId?: number | null
  sortOrder?: OrderConfig
  searchValue?: string
}

const useMediaView = ({ mediaType, folderId = null, sortOrder, searchValue }: UseMediaViewProps) => {
  const defaultPageSize = 25

  const defaultOrderBy: OrderConfig[] = [sortOrder || { column: 'created_at_epoch', ascending: false }]

  const defaultFileColumns = [
    'id',
    'name',
    'preview_file_id',
    'file_id',
    'tags',
    'preview_link',
    'created_at_epoch',
    'size',
    'extension'
  ]
  const defaultFolderColumns = ['id', 'name', 'created_at_epoch', 'parent_folder_id']

  const observer = useRef<IntersectionObserver>()

  const { data, fetchNextPage, isLoading, hasNextPage, isFetching } = useInfiniteQuery({
    ...queries.files.media(folderId, mediaType),
    queryFn: async ({ pageParam = 1 }) => {
      const defaultFilterBy: FilterConfig = !!folderId
        ? { attribute: 'parent_folder_id', operator: '==', value: folderId }
        : { attribute: 'parent_folder_id', operator: 'is_null', value: true }

      const defaultFolderFilters: FilterParams = {
        columns: defaultFolderColumns,
        order_by: sortOrder?.column !== 'size' ? defaultOrderBy : [{ column: 'name', ascending: true }],
        search_by: ['name'].map(type => ({ attribute: type, operator: 'ilike', value: searchValue })),
        offset: (pageParam - 1) * defaultPageSize,
        limit: defaultPageSize
      }

      const defaultFolderRequest: GetMediaRequest = {
        entityType: 'folder',
        queryFilters: { filter_by: [defaultFilterBy], ...defaultFolderFilters }
      }
      const { data: folderData } = await mediaStorageService.getMediaList(defaultFolderRequest)

      const defaultFileFilters: FilterParams = {
        columns: defaultFileColumns,
        order_by: defaultOrderBy,
        search_by: ['name', 'tags'].map(type => ({ attribute: type, operator: 'ilike', value: searchValue })),
        offset: (pageParam - 1) * defaultPageSize,
        limit: defaultPageSize
      }

      const defaultFileRequest: GetMediaRequest = {
        entityType: 'file',
        queryFilters: { filter_by: [defaultFilterBy], ...defaultFileFilters }
      }

      // if (mediaType && mediaType !== 'folder') {
      //   defaultFileRequest.queryFilters?.filter_by?.push({
      //     attribute: 'extension',
      //     operator: 'in',
      //     value: extensions[mediaType]
      //   })
      // }
      const { data: fileData } = await mediaStorageService.getMediaList(defaultFileRequest)

      const folderDataArray = Array.isArray(folderData.result) ? (folderData.result as FolderType[]) : []
      const fileDataArray = Array.isArray(fileData.result) ? (fileData.result as FileType[]) : []

      const filteredFiles = fileDataArray.filter(file => {
        const baseFileName = file.name.replace(/(\s\(\d+\))+$/g, '')
        const lastDotIndex = baseFileName.lastIndexOf('.')
        const nameExtension = lastDotIndex !== -1 ? baseFileName.substring(lastDotIndex + 1) : ''

        return file.extension && (file.extension === nameExtension || file.extension === '.' + nameExtension)
      })

      return [
        ...folderDataArray.map(item => ({ ...item, type: 'folder' } as FolderType)),
        ...filteredFiles.map(
          item =>
          ({
            ...item,
            type: mediaType || getMediaTypeByExtension(extensions, item.extension)
          } as FileType)
        )
      ]
    },
    getNextPageParam: (lastPage, allPages) => {
      return lastPage.length ? allPages.length + 1 : undefined
    },
    initialPageParam: 1
  })

  const lastElementRef = useCallback(
    (node: HTMLLIElement) => {
      if (isLoading) return

      if (observer.current) observer.current.disconnect()

      observer.current = new IntersectionObserver(entries => {
        console.log(entries[0].isIntersecting, hasNextPage, !isFetching)
        if (entries[0].isIntersecting && hasNextPage && !isFetching) {
          fetchNextPage()
        }
      })

      if (node) observer.current.observe(node)
    },
    [fetchNextPage, hasNextPage, isFetching, isLoading]
  )

  return { data, isFetching, lastElementRef, fetchNextPage }
}

export default useMediaView

function getMediaTypeByExtension(obj: typeof extensions, searchValue?: string) {
  if (!searchValue) return
  let i = 0
  const entries = Object.entries(obj)
  while (i < entries.length && !entries[i][1].includes(searchValue)) {
    i++
  }
  if (i < entries.length) {
    return entries[i][0]
  }
}
