import { useCallback } from 'react'

import { Stack, Typography, useTheme } from '@mui/material'

import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

const useToast = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'ALERT' })
  const theme = useTheme()

  const notify = useCallback(
    (message: string, options?: { type: 'success' | 'error' }) => {
      const type = options?.type || 'success'
      toast[type](t(`${type.toUpperCase()}.${message}`, message))
    },
    [t]
  )

  const notifyWithDescription = useCallback(
    (message: string, description: string, options?: { type: 'success' | 'error' }) => {
      const type = options?.type || 'success'
      toast[type](() => (
        <Stack maxWidth={300} spacing={theme.spacing(3)}>
          <Typography variant='h5' fontWeight={500} color={theme.palette.common.white}>
            {t(`${type.toUpperCase()}.${message}`, message)}
          </Typography>
          <Typography fontWeight={500} color={theme.palette.common.white}>
            {t(`${type.toUpperCase()}.${description}`, description)}
          </Typography>
        </Stack>
      ))
    },
    [t, theme]
  )

  return { notify, notifyWithDescription }
}

export default useToast
