import { useState } from 'react'

import Link from 'next/link'

import {
  Box,
  Typography,
  Stack,
  Button,
  Checkbox,
  InputAdornment,
  IconButton,
  BoxProps,
  FormControlLabel,
  FormControlLabelProps,
  Dialog,
  useMediaQuery
} from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'

import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as zod from 'zod'
import { useTranslation } from 'react-i18next'
import { Icon } from '@iconify/react'

import { CustomTextField } from 'src/@core/components/mui/text-field'

import { useAuth } from 'src/hooks/useAuth'
import { usePortalSettings } from 'src/hooks/usePortalSettings'
import { useAuthForm } from 'src/hooks/auth/useAuthForm'

import { getImageUrl } from 'src/utils/getImageUrl'
import { getGDPRLinks } from 'src/utils/settings/portal-settings/utils'

import themeConfig from 'src/configs/themeConfig'

import { RegistrationForm } from '../Forms'

import { RegistrationFormType } from 'src/schemas/auth'
import { RegistrationType } from 'src/types/settings/portal-settings'

const schema = zod.object({
  email: zod.string().min(1),
  password: zod.string().min(1)
})

interface FormData {
  email: string
  password: string
}

interface LoginModalProps {
  isOpen: boolean
  close: () => void
}

const LoginDialog = ({ isOpen, close }: LoginModalProps) => {
  const { t: tGlobal } = useTranslation('translation')
  const { t } = useTranslation('translation', { keyPrefix: 'PAGE.LOGIN' })
  const [formState, setFormState] = useState('login')
  const theme = useTheme()
  const { portalSettings } = usePortalSettings()
  const [showPassword, setShowPassword] = useState(false)
  const [rememberMe, setRememberMe] = useState(true)
  const { registerSingle, registerDouble, isRegisterLoading, login } = useAuth()
  const { registrationForm } = useAuthForm()

  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'))
  const loginSettings = portalSettings?.content_login_page_settings?.[0]
  const configuration = portalSettings?.configuration?.[0]
  const generalDesign = portalSettings?.general_design_settings?.[0]

  const {
    control,
    setError,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>({
    defaultValues: { email: '', password: '' },
    mode: 'onBlur',
    resolver: zodResolver(schema)
  })

  const onSubmitLogin = async (data: FormData) => {
    login({ login: data.email, password: data.password }, () => {
      setError('email', { type: 'manual', message: t('VALIDATIONS.invalid_fields') })
    })
  }

  const onSubmitRegister = async (data: RegistrationFormType) => {
    const params = {
      login: data.email,
      first_name: data.firstName,
      last_name: data.lastName,
      password: data.password
    }

    configuration?.registration_type === RegistrationType.REGISTER_SINGLE
      ? registerSingle(params)
      : registerDouble(params)
  }

  const Wrapper = styled(Box)<BoxProps>(() => ({
    width: '550px'
  }))

  const LinkStyled = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    color: `${theme.palette.primary.main} !important`
  }))

  const StyledLogo = styled(Box)({
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: 48,
    height: 48
  })

  const StyledFormControlLabel = styled(FormControlLabel)<FormControlLabelProps>(({ theme }) => ({
    '& .MuiFormControlLabel-label': {
      color: theme.palette.text.secondary
    }
  }))

  const CloseButton = styled(Stack)(({ theme }) => ({
    position: 'absolute',
    zIndex: 1,
    top: theme.spacing(-4),
    right: theme.spacing(-4),
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[4]
  }))

  const StyledIconButton = styled(IconButton)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius
  }))

  return (
    <Dialog
      open={isOpen}
      onClose={close}
      PaperProps={{
        sx: { overflow: 'visible', position: 'relative' }
      }}
    >
      <CloseButton>
        <StyledIconButton disableRipple onClick={close} aria-label='close'>
          <Icon icon='tabler:x' width='18px' height='18px' />
        </StyledIconButton>
      </CloseButton>

      <Wrapper>
        <Box
          className={isMediumScreen ? 'content-center' : ''}
          sx={{
            p: [6, 12],
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Box sx={{ width: '100%' }}>
            {generalDesign?.design_image && generalDesign.design_image?.[0]?.big_logo && (
              <StyledLogo
                sx={{
                  backgroundImage: generalDesign?.design_image?.[0]?.big_logo
                    ? `url(${getImageUrl(generalDesign?.design_image?.[0]?.big_logo)})`
                    : `url('/app/images/defaultBackgroundImg.png')`
                }}
              />
            )}

            <Stack paddingY={theme.spacing(6.5)} gap={theme.spacing(1.5)}>
              {loginSettings?.show_title && (
                <Typography variant='h3'>
                  {loginSettings.title || `${t('title', { portalName: themeConfig.templateName })}! 👋🏻`}
                </Typography>
              )}

              {loginSettings?.show_subtitle && (
                <Typography variant='h5' sx={{ color: 'text.secondary' }}>
                  {loginSettings.subtitle || `${t('subtitle')}`}
                </Typography>
              )}

              {loginSettings?.show_description && (
                <Typography variant='body1' sx={{ color: 'text.secondary' }}>
                  {loginSettings.description}
                </Typography>
              )}
            </Stack>

            {formState === 'login' && (
              <form noValidate onSubmit={handleSubmit(onSubmitLogin)}>
                <Stack gap={theme.spacing(4)}>
                  <Controller
                    name='email'
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <Typography>{t('FORM.email_label')}</Typography>
                          <Typography
                            variant='body2'
                            component={LinkStyled}
                            href='/forgot-password'
                            sx={{ textDecoration: 'none' }}
                          >
                            {t('forgot_password')}
                          </Typography>
                        </Box>

                        <CustomTextField
                          fullWidth
                          value={value}
                          onBlur={onBlur}
                          onChange={onChange}
                          placeholder={t('FORM.email_placeholder')}
                          error={Boolean(errors.email)}
                          {...(errors.email && { helperText: errors.email.message })}
                        />
                      </Box>
                    )}
                  />

                  <Controller
                    name='password'
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <CustomTextField
                        fullWidth
                        value={value}
                        onBlur={onBlur}
                        label={t('FORM.password_label')}
                        onChange={onChange}
                        id='auth-login-v2-password'
                        error={Boolean(errors.password)}
                        placeholder={t('FORM.password_placeholder')}
                        {...(errors.password && { helperText: errors.password.message })}
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <IconButton
                                edge='end'
                                onMouseDown={e => e.preventDefault()}
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                <Icon fontSize='1.25rem' icon={showPassword ? 'tabler:eye' : 'tabler:eye-off'} />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    )}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <StyledFormControlLabel
                      label={t('FORM.remember_me_label')}
                      control={<Checkbox checked={rememberMe} onChange={e => setRememberMe(e.target.checked)} />}
                    />
                  </Box>
                  <Button onClick={handleSubmit(onSubmitLogin)} fullWidth type='submit' variant='contained'>
                    {t('BUTTON.login')}
                  </Button>

                  {configuration?.allowed_register && (
                    <Stack
                      alignItems='center'
                      flexWrap='wrap'
                      justifyContent='center'
                      flexDirection='row'
                      gap={theme.spacing(2)}
                    >
                      {loginSettings?.show_registration_description && (
                        <Typography sx={{ color: 'text.secondary' }}>
                          {loginSettings?.registration_description || t('registration_title')}
                        </Typography>
                      )}

                      {configuration?.allowed_register && (
                        <Typography
                          sx={{ cursor: 'pointer' }}
                          onClick={() => setFormState('register')}
                          color={'primary.main'}
                        >
                          {t('create_account')}
                        </Typography>
                      )}
                    </Stack>
                  )}

                  <Stack direction='row' gap={theme.spacing(4)} justifyContent={'center'}>
                    {getGDPRLinks(portalSettings?.settings_imprint_gdrp?.[0]?.show_terms_page).map(({ title, url }) => (
                      <Typography
                        key={title}
                        component={'a'}
                        href={url}
                        variant='body1'
                        sx={{ textDecoration: 'none' }}
                      >
                        {tGlobal(title)}
                      </Typography>
                    ))}
                  </Stack>
                </Stack>
              </form>
            )}

            {formState === 'register' && (
              <RegistrationForm
                isLoading={isRegisterLoading}
                onSubmit={onSubmitRegister}
                form={registrationForm}
                isLoginDialog
                backToLogin={() => setFormState('login')}
                requiredChecks={{
                  showTermsPage: portalSettings?.settings_imprint_gdrp?.[0]?.show_terms_page,
                  requireTosApproval: configuration?.require_tos_approval
                }}
              />
            )}
          </Box>
        </Box>
      </Wrapper>
    </Dialog>
  )
}

export default LoginDialog
