import { useRouter } from 'next/router'

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import memberService from '../../service/member-service'
import useToast from '../useToast'
import queries from '../../queries'

export const useUpdateMember = () => {
  const { notify } = useToast()
  const { t } = useTranslation()

  const queryClient = useQueryClient()
  const router = useRouter()

  const {
    mutate: updateMember,
    mutateAsync: updateMemberAsync,
    isPending: isSubmittingMember
  } = useMutation({
    mutationFn: memberService.update,
    onSuccess: () => notify(t('ALERT.SUCCESS.MEMBER.update'), { type: 'success' }),
    onError: error => notify(error.message || t('ALERT.ERROR.MEMBER.update'), { type: 'error' }),
    onSettled: (_, _e, { id }) => {
      queryClient.invalidateQueries({
        queryKey: [
          queries.members.allMembers.queryKey,
          { limit: parseInt(`${router.query?.limit}`), page: parseInt(`${router.query?.page}`) }
        ]
      })
      queryClient.invalidateQueries({ queryKey: [queries.members.detail(id!)] })
    }
  })

  return {
    updateMemberAsync,
    updateMember,
    isSubmittingMember
  }
}
