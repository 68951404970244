import React, { createContext, useState, useEffect } from 'react'

import { Button, Stack, styled, Typography, useTheme } from '@mui/material'

import { I18nextProvider, useTranslation } from 'react-i18next'

import Spinner from 'src/@core/components/spinner'

import { PORTAL_SETTINGS_STORAGE_KEY } from 'src/utils/settings/portal-settings/constants'

import i18n from 'src/configs/i18n'

import settingsService from 'src/service/settings-service'

import { languageMap } from './constants'

import { PortalMemberSettings } from 'src/types/settings/portal-settings'

const StyledButton = styled(Button)({
  alignSelf: 'center'
})

type PortalSettingsProviderProps = {
  children: React.ReactNode
}

type PortalSettingsType = {
  portalSettings: PortalMemberSettings | null
  updateSettings: (portalSettings: PortalMemberSettings) => void
}

const PortalSettingsContext = createContext<PortalSettingsType>({
  portalSettings: null,
  updateSettings: () => {}
})

const loadSettings = (): PortalMemberSettings | null => {
  if (typeof window === 'undefined') return null

  try {
    const storedSettings = window?.localStorage.getItem(PORTAL_SETTINGS_STORAGE_KEY)

    return storedSettings ? JSON.parse(storedSettings) : null
  } catch (error) {
    console.error('Error loading settings from localStorage:', error)

    return null
  }
}

const saveSettings = (portalSettings: PortalMemberSettings) => {
  try {
    window?.localStorage.setItem(PORTAL_SETTINGS_STORAGE_KEY, JSON.stringify(portalSettings))
  } catch (error) {
    console.error('Error saving settings to localStorage:', error)
  }
}

const PortalSettingsProvider = ({ children }: PortalSettingsProviderProps) => {
  const theme = useTheme()
  const { t } = useTranslation('translation', { keyPrefix: 'PORTAL_SETTINGS' })
  const [portalSettings, setPortalSettings] = useState<PortalMemberSettings | null>(loadSettings())
  const [loading, setLoading] = useState(!portalSettings) // Only show loading if no local settings
  const [isError, setIsError] = useState(false)

  const updateSettings = (newSettings: PortalMemberSettings) => {
    saveSettings(newSettings)
    setPortalSettings(newSettings)
  }

  const fetchSettings = async () => {
    try {
      const res = await settingsService.getPortalSettings()
      updateSettings(res.data.result)
      setIsError(false)
    } catch (error) {
      console.error('Failed to fetch settings:', error)
      if (!portalSettings) setIsError(true) // Only show error if no local settings
    } finally {
      setLoading(false)
    }
  }

  const retryAction = async () => {
    setLoading(true)
    setIsError(false)
    await fetchSettings()
  }

  useEffect(() => {
    fetchSettings() // Fetch fresh settings in the background
  }, [])

  useEffect(() => {
    if (portalSettings?.general?.language_id) {
      const lang = languageMap[portalSettings.general.language_id] || 'en'
      i18n.changeLanguage(lang)
    }
  }, [portalSettings?.general?.language_id])

  const portalSettingsContext: PortalSettingsType = {
    portalSettings,
    updateSettings
  }

  if (loading && !portalSettings) {
    return <Spinner />
  }

  if (isError && !portalSettings) {
    return (
      <Stack gap={theme.spacing(1.5)} height={'100vh'} justifyContent='center' alignItems='center'>
        <Typography variant='h5'>{t('fetch_error')}</Typography>

        <StyledButton variant='contained' onClick={retryAction}>
          {t('retry')}
        </StyledButton>
      </Stack>
    )
  }

  return (
    <PortalSettingsContext.Provider value={portalSettingsContext}>
      <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
    </PortalSettingsContext.Provider>
  )
}

export { PortalSettingsContext, PortalSettingsProvider }
