import { z } from 'zod'
import * as zod from 'zod'

// 8 - 32 chars long
// one lowercase character
// one uppercase character
// one number
// one non word character + underscore
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,32}$/

export const PasswordUpdateValidation = z
  .object({
    password: z.string({ errorMap: () => ({ message: 'required' }) }).min(1),
    confirm_password: z.string({ errorMap: () => ({ message: 'required' }) }).min(1),
    new_password: z
      .string({ errorMap: () => ({ message: 'new_password' }) })
      .regex(passwordRegex)
      .min(1)
  })
  .superRefine(({ password, new_password, confirm_password }, ctx) => {
    if (confirm_password !== new_password)
      ctx.addIssue({ message: 'passwords_do_not_match', code: z.ZodIssueCode.custom, path: ['confirm_password'] })
    if (!password) ctx.addIssue({ message: 'required', code: z.ZodIssueCode.custom, path: ['password'] })
    if (!new_password) ctx.addIssue({ message: 'required', code: z.ZodIssueCode.custom, path: ['new_password'] })
    if (!confirm_password)
      ctx.addIssue({ message: 'required', code: z.ZodIssueCode.custom, path: ['confirm_password'] })
  })

export type PasswordUpdateFormData = zod.infer<typeof PasswordUpdateValidation>
