import { NavGroup, NavSectionTitle } from 'src/@core/layouts/types'

import { permissionActions } from 'src/configs/permissionActions'

import { subjects } from '../subjects'

import community from './community'
import members from './members'

import { Community } from 'src/types/community'

interface UsersMenuProps {
  communities?: Community[]
  isLoadingCommunities: boolean
  showCommunities: boolean
  communityCustomName?: string
}

const usersMenu = ({
  communities,
  isLoadingCommunities,
  showCommunities,
  communityCustomName
}: UsersMenuProps): (NavGroup | NavSectionTitle)[] => [
  {
    sectionTitle: 'NAVIGATION.usersMenu.users',
    subject: subjects.topMenu,
    action: permissionActions.manage,
    aclSubject: subjects.ccPage
  },
  ...community({ communities, isLoadingCommunities, showCommunities, communityCustomName }),
  ...members
]

export default usersMenu
