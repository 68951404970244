import { createContext, useEffect, useState, ReactNode } from 'react'

import { useRouter } from 'next/router'

import { useTranslation } from 'react-i18next'
import { AxiosError } from 'axios'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import useToast from 'src/hooks/useToast'
import { useInterval } from 'src/hooks/useInterval'

import { redirectByRole } from 'src/layouts/components/acl/redirect'

import { isStringRecord } from 'src/utils/types/isStringRecord'

import authConfig from 'src/configs/auth'

import authService from 'src/service/auth-service'

import { UserRolesType } from '../utils/getUserRole'

import {
  AuthValuesType,
  LoginParams,
  ErrCallbackType,
  UserDataType,
  UserFromApi,
  RegisterActivateRequest,
  RegisterRequest,
  ResetPasswordRequest,
  RegisterVerifyRequest
} from './types'

const defaultProvider: AuthValuesType = {
  user: null,
  loading: true,
  isRegisterLoading: false,
  isResetPasswordLoading: false,
  isResetPasswordSuccess: false,
  isPreviewMode: false,
  isLoginAsPreviewPending: false,
  setUser: () => null,
  removeUser: () => {},
  setLoading: () => Boolean,
  saveAuthData: () => {},
  mapUserFromApi: (_result: UserFromApi): UserDataType => {
    return {
      id: 0,
      portal: {
        id: 0,
        type_id: 0,
        language_id: 0,
        is_new_design_enabled: false,
        is_leader_board_shown: false,
        domain: ''
      },
      role: '',
      email: '',
      fullName: '',
      firstName: '',
      lastName: '',
      username: '',
      token: ''
    }
  },
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  registerContentCreator: () => Promise.resolve(),
  registerSingle: () => Promise.resolve(),
  registerDouble: () => Promise.resolve(),
  registerActivate: () => Promise.resolve(),
  registerVerify: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
  loginAsPreview: () => Promise.resolve(),
  reset: {
    resetResetPasswordMutation: () => {}
  }
}

const FIVE_MINUTES = 300000

const AuthContext = createContext(defaultProvider)

type Props = {
  children: ReactNode
}

const mapUserFromApi = (result: UserFromApi): UserDataType => {
  return {
    id: result.portal_member.id,
    portal: result.portal,
    role: result.portal_member.role_id.toString(),
    email: result.portal_member.email,
    fullName: `${result.portal_member.first_name} ${result.portal_member.last_name}`,
    firstName: result.portal_member.first_name,
    lastName: result.portal_member.last_name,
    username: result.portal_member.email,
    token: result.token
  }
}

const AuthProvider = ({ children }: Props) => {
  // ** States
  const [user, setUser] = useState<UserDataType | null>(defaultProvider.user)
  const [loading, setLoading] = useState<boolean>(defaultProvider.loading)
  const [isPreviewMode, setIsPreviewMode] = useState<boolean>(false)

  // ** Hooks
  const router = useRouter()
  const { notify, notifyWithDescription } = useToast()
  const { t } = useTranslation('translation', { keyPrefix: 'PAGE' })

  const queryClient = useQueryClient()

  const saveAuthData = (data: UserFromApi, isFromSetInterval?: boolean) => {
    const userMap = mapUserFromApi(data)
    setUser(userMap)

    const expiresInDays = 1
    const expiryDate = new Date()
    expiryDate.setDate(expiryDate.getDate() + expiresInDays)

    if (isFromSetInterval) {
      const isPreviewMode = window?.sessionStorage.getItem('preview') === 'true'

      const storage = isPreviewMode ? window?.sessionStorage : window?.localStorage

      storage.setItem(authConfig.storageTokenKeyName, JSON.stringify(userMap))
      storage.setItem(authConfig.mentorToolsUserObjectKeyName, JSON.stringify(data))
    } else {
      window.localStorage.setItem(authConfig.storageTokenKeyName, JSON.stringify(userMap))
      window.localStorage.setItem(authConfig.mentorToolsUserObjectKeyName, JSON.stringify(data))
    }
  }

  const reset = () => {
    localStorage.removeItem(authConfig.storageTokenKeyName)
    localStorage.removeItem(authConfig.mentorToolsUserObjectKeyName)

    setUser(null)
    setLoading(false)
    if (!router.pathname.includes('login')) {
      router.replace('/login')
    }
  }

  useInterval(async () => {
    const token = authService.getUserToken()
    if (!token) return

    try {
      const response = await authService.prolongate({ token })
      if (response.status === 401) {
        reset()

        return
      }

      const user = response.data.result
      setUser(mapUserFromApi(user))
      saveAuthData(user, true)
    } catch (error) {
      reset()
    }
  }, FIVE_MINUTES)

  const handlePreviewUserMessage = (event: MessageEvent) => {
    if (event.data?.type === 'SET_PREVIEW_USER') {
      const res = event.data.user as UserFromApi

      const userMap = {
        id: res.portal_member.id,
        portal: res.portal,
        role: res.portal_member.role_id.toString(),
        email: res.portal_member.email,
        fullName: `${res.portal_member.first_name} ${res.portal_member.last_name}`,
        firstName: res.portal_member.first_name,
        lastName: res.portal_member.last_name,
        username: res.portal_member.email,
        token: res.token
      }

      window?.sessionStorage.setItem(authConfig.storageTokenKeyName, JSON.stringify(userMap))
      window?.sessionStorage.setItem(authConfig.mentorToolsUserObjectKeyName, JSON.stringify(event.data.user))
      window?.sessionStorage.setItem('preview', 'true')

      setUser(userMap)
    }
  }

  useEffect(() => {
    if (window.opener) {
      window.opener.postMessage('REQUEST_PREVIEW_USER', '*')

      window?.addEventListener('message', handlePreviewUserMessage)

      return () => {
        window?.removeEventListener('message', handlePreviewUserMessage)
      }
    }
  }, [])

  useEffect(() => {
    const storedUser = window?.sessionStorage.getItem(authConfig.storageTokenKeyName)
    const isPreview = window?.sessionStorage.getItem('preview')

    if (isPreview === 'true' && storedUser) {
      setIsPreviewMode(true)
      setUser(JSON.parse(storedUser))
    }
  }, [])

  const initAuth = async (): Promise<void> => {
    const isPreviewMode = window?.sessionStorage.getItem('preview') === 'true'
    const rawToken = router.asPath.split('hash=')[1] || router.query?.hash || authService.getUserToken()
    const token = Array.isArray(rawToken) ? rawToken[0] : rawToken

    if (!token) {
      setLoading(false)

      return
    }

    setLoading(true)

    try {
      const response = await authService.prolongate({ token })

      if (response.status === 401) {
        reset()

        return
      }

      setLoading(false)
      const user = response.data.result
      setUser(mapUserFromApi(user))

      if (isPreviewMode) {
        window?.sessionStorage.setItem(authConfig.storageTokenKeyName, JSON.stringify(mapUserFromApi(user)))
        window?.sessionStorage.setItem(authConfig.mentorToolsUserObjectKeyName, JSON.stringify(user))

        return
      }

      saveAuthData(user)
      if (!router.query.hash) return

      if (String(user.portal_member.role_id) === UserRolesType.CUSTOMER && router.query.course_id) {
        window.location.href = window.location.origin + '/app/customer/course?course_id=' + router.query.course_id
      }
    } catch (error) {
      reset()
    }
  }

  useEffect(() => {
    initAuth()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query.hash])

  const handleLogin = async (params: LoginParams, errorCallback?: ErrCallbackType) => {
    try {
      const response = await authService.login(params)
      const { result } = response.data

      saveAuthData(result)

      const userData = mapUserFromApi(result)

      redirectByRole(userData)
    } catch (error) {
      if (isStringRecord(error)) {
        errorCallback?.(error)
      }
    }
  }

  const { mutateAsync: loginAsPreview, isPending: isLoginAsPreviewPending } = useMutation({
    mutationFn: authService?.loginAsHiddenCustomer,
    onSuccess: response => {
      const { result } = response.data

      result.portal_member.first_name = t('COMMON.preview_mode_first_name')
      result.portal_member.last_name = t('COMMON.preview_mode_last_name')
      result.portal_member.nickname = t('COMMON.preview_mode_nickname')

      const previewTab = window.open('/app/customer/dashboard/?preview=true', '_blank')

      if (previewTab) {
        setIsPreviewMode(true)

        // Listen for a request from the new tab
        const handleMessage = (event: MessageEvent) => {
          if (event.data === 'REQUEST_PREVIEW_USER') {
            previewTab.postMessage({ type: 'SET_PREVIEW_USER', user: result }, '*')
          }
        }

        window.addEventListener('message', handleMessage)

        const interval = setInterval(() => {
          if (!previewTab || previewTab.closed) {
            window.removeEventListener('message', handleMessage)
            clearInterval(interval)
          }
        }, 1000)
      } else {
        console.error('Popup blocked! Ensure pop-ups are enabled.')
      }
    }
  })

  const { mutateAsync: registerSingle, isPending: isRegisterSingleLoading } = useMutation({
    mutationFn: (params: RegisterRequest) => authService.registerSingle(params),
    onSuccess: response => {
      const { result } = response.data
      saveAuthData(result)

      const userData = mapUserFromApi(result)
      redirectByRole(userData)

      notifyWithDescription(t('REGISTER.NOTIFICATION.label'), t('REGISTER.NOTIFICATION.success_register_single'), {
        type: 'success'
      })
    },
    onError: () => {
      notify('REGISTER.NOTIFICATION.something_went_wrong', { type: 'error' })
    }
  })

  const { mutateAsync: registerContentCreator, isPending: isRegisterContentCreatorLoading } = useMutation({
    mutationFn: (params: RegisterRequest) => authService.registerContentCreator(params),
    onSuccess: async response => {
      const { result } = response.data
      const prolongateResponse = await authService.prolongate({ token: result.token })
      saveAuthData(prolongateResponse.data.result)

      const userData = mapUserFromApi(prolongateResponse.data.result)

      redirectByRole(userData)

      notifyWithDescription(t('REGISTER.NOTIFICATION.label'), t('REGISTER.NOTIFICATION.success_register_single'), {
        type: 'success'
      })
    },
    onError: () => {
      notify('REGISTER.NOTIFICATION.something_went_wrong', { type: 'error' })
    }
  })

  const { mutateAsync: registerDouble, isPending: isRegisterDoubleLoading } = useMutation({
    mutationFn: (params: RegisterRequest) => authService.registerSingle(params),
    onSuccess: () => {
      notifyWithDescription(t('REGISTER.NOTIFICATION.label'), t('REGISTER.NOTIFICATION.success_register_double'), {
        type: 'success'
      })

      router.replace('/login')
    },
    onError: () => {
      notify('REGISTER.NOTIFICATION.something_went_wrong', { type: 'error' })
    }
  })

  const { mutateAsync: registerVerify } = useMutation({
    mutationFn: (params: RegisterVerifyRequest) => authService.registerVerify(params),
    onSuccess: async response => {
      const { result } = response.data

      if (result.is_verify) {
        const prolongateResponse = await authService.prolongate({ token: result.token })
        saveAuthData(prolongateResponse.data.result)

        const userData = mapUserFromApi(prolongateResponse.data.result)

        redirectByRole(userData)

        notify(t('REGISTER.NOTIFICATION.user_activation'), { type: 'success' })
      }
    },
    onError: error => {
      const axiosError = error as AxiosError<{ error: string }>
      const errorMessage = axiosError.response?.data?.error

      notify(t(errorMessage || 'REGISTER.NOTIFICATION.something_went_wrong'), { type: 'error' })
    }
  })

  const { mutateAsync: registerActivate } = useMutation({
    mutationFn: (params: RegisterActivateRequest) => authService.registerActivate(params),
    onSuccess: async response => {
      const { result } = response.data

      if (result.token) {
        const prolongateResponse = await authService.prolongate({ token: result.token })
        saveAuthData(prolongateResponse.data.result)

        const userData = mapUserFromApi(prolongateResponse.data.result)

        redirectByRole(userData)

        notifyWithDescription(
          t('REGISTER.NOTIFICATION.account_confirmed_label'),
          t('REGISTER.NOTIFICATION.account_confirmed'),
          { type: 'success' }
        )
      }
    },
    onError: error => {
      const axiosError = error as AxiosError<{ error: string }>
      const errorMessage = axiosError.response?.data?.error

      notify(t(errorMessage || 'REGISTER.NOTIFICATION.something_went_wrong'), { type: 'error' })
    }
  })

  const {
    mutateAsync: resetPassword,
    isPending: isResetPasswordLoading,
    isSuccess: isResetPasswordSuccess,
    reset: resetResetPasswordMutation
  } = useMutation({
    mutationFn: (params: ResetPasswordRequest) => authService.resetPassword(params),
    onSuccess: () => notify(t('FORGOT_PASSWORD.NOTIFICATION.success'), { type: 'success' }),
    onError: error => {
      const axiosError = error as AxiosError<{ error: string }>
      const errorMessage = axiosError.response?.data?.error

      notify(errorMessage || t('REGISTER.NOTIFICATION.something_went_wrong'), { type: 'error' })
    }
  })

  const handleLogout = () => {
    const isPreviewMode = window?.sessionStorage.getItem('preview') === 'true'

    const storage = isPreviewMode ? window?.sessionStorage : window?.localStorage

    storage.removeItem(authConfig.mentorToolsUserObjectKeyName)
    storage.removeItem(authConfig.storageTokenKeyName)
    storage.removeItem(authConfig.portalSettingsKeyName)

    setUser(null)
    queryClient.clear()

    router.push('/login')
  }

  const removeUser = () => {
    localStorage.removeItem(authConfig.storageTokenKeyName)
    localStorage.removeItem(authConfig.mentorToolsUserObjectKeyName)

    setUser(null)

    queryClient.clear()
  }

  const values = {
    user,
    loading,
    isRegisterLoading: isRegisterSingleLoading || isRegisterDoubleLoading || isRegisterContentCreatorLoading,
    isResetPasswordLoading,
    isResetPasswordSuccess,
    setUser,
    removeUser,
    setLoading,
    login: handleLogin,
    logout: handleLogout,
    registerContentCreator,
    registerSingle,
    registerDouble,
    registerActivate,
    registerVerify,
    resetPassword,
    mapUserFromApi,
    saveAuthData,
    isPreviewMode,
    loginAsPreview,
    isLoginAsPreviewPending,
    reset: {
      resetResetPasswordMutation
    }
  }

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

export { AuthContext, AuthProvider }
