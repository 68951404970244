import { useState, useEffect, useRef, ChangeEvent, useMemo } from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { useDebounce } from '@uidotdev/usehooks'
import { useTranslation } from 'react-i18next'

import { useSelectedMedia } from 'src/contexts/SelectedMediaContext'

import useMediaView from 'src/hooks/files/useMediaView'
import useMediaFolders from 'src/hooks/files/useMediaFolders'

import DeleteConfirmationModal from 'src/components/common/modals/DeleteConfirmationModal'
import TextInputModal from 'src/components/media-storage/modals/TextInputModal'
import EditFileModal from 'src/components/media-storage/modals/EditFileModal'

import queries from 'src/queries'
import env from 'src/envs'

import { useMediaStorageMutation } from './files/useMediaStorageMutation'
import { useAuth } from './useAuth'
import useModal from './useModal'

import { MediaVariant } from 'src/types/files/media'
import { FileType } from 'src/types/files/fileData'
import { FolderType } from 'src/types/files/folder'

type Props = {
  mediaType: MediaVariant
}

export const useMediaStorage = ({ mediaType }: Props) => {
  const { t } = useTranslation()
  const auth = useAuth()
  const { isSelected } = useSelectedMedia()

  const [isActive, setActive] = useState<FileType | FolderType>()
  const [mediaSortOrder, setMediaSortOrder] = useState<string>('created_at_epochf')
  const [searchValue, setSearchValue] = useState<string>('')
  const debouncedSearchValue = useDebounce(searchValue, 500)
  const [isThumbnail, setIsThumbnail] = useState<boolean>(false)

  const {
    data: infiniteMediaList,
    isFetching: mediaViewIsfetching,
    fetchNextPage
  } = useMediaView({
    mediaType,
    folderId: isActive?.id,
    sortOrder: { column: mediaSortOrder.slice(0, -1), ascending: mediaSortOrder.slice(-1) === 't' },
    searchValue: debouncedSearchValue || ''
  })
  const { data: initialFolderList } = useMediaFolders()
  const {
    readMediaFile,
    handleMediaUpload,
    handleMediaDelete,
    handleAddFolder,
    handleDeleteFolder,
    handleRenameFolder,
    handleFileEdit,
    handleDuplicateFile,
    addThumbnail
  } = useMediaStorageMutation({
    folderId: !isThumbnail && isActive ? isActive?.id : -1,
    mediaType
  })

  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const thumbnailRef = useRef<HTMLInputElement | null>(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const mediaList = useMemo(() => infiniteMediaList?.pages.flatMap(media => media), [infiniteMediaList])

  const [folderList, setFolderList] = useState<FolderType[]>()

  const [folderSortOrder, setFolderSortOrder] = useState<string>('name-a-z')

  const queryClient = useQueryClient()
  useEffect(() => {
    queryClient.invalidateQueries({ ...queries.files.media(isActive?.id || -1, mediaType) })
  }, [mediaType, mediaSortOrder, debouncedSearchValue, isActive])

  useEffect(() => setFolderList(initialFolderList), [initialFolderList])

  useEffect(() => {
    if (!folderList) return

    const tempFolderList = [...folderList]

    switch (folderSortOrder) {
      case 'name-a-z':
        tempFolderList.sort((a, b) => a.name.localeCompare(b.name))
        break
      case 'name-z-a':
        tempFolderList.sort((a, b) => b.name.localeCompare(a.name))
        break
      case 'creation_date-newest-first':
        tempFolderList.sort((a, b) => new Date(b.created_at_epoch).getTime() - new Date(a.created_at_epoch).getTime())
        break
      case 'creation_date-oldest-first':
        tempFolderList.sort((a, b) => new Date(a.created_at_epoch).getTime() - new Date(b.created_at_epoch).getTime())
        break
      default:
        break
    }

    setFolderList(tempFolderList)
  }, [folderSortOrder])

  //* Modal states */
  const [isRenameModalOpen, setRenameModalOpen] = useState<boolean>(false)
  const [isNewFolderModalOpen, setNewFolderModalOpen] = useState<boolean>(false)
  const [isEditFileModalOpen, setEditFileModalOpen] = useState<boolean>(false)
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  const { isOpen: isMentorCloudOpen, close: closeMentorCloud, open: openMentorCloud } = useModal()

  const deleteEntity = () => {
    setAnchorEl(null)
    if (isSelected?.type !== 'folder') {
      const fileId = (isSelected as FileType).id.toString()
      if (fileId) {
        handleMediaDelete(fileId)
      }
    } else {
      handleDeleteFolder(isSelected.id)
    }
  }

  const edit = (newData: FileType) => {
    const fileId = newData.id
    if (fileId && auth.user) {
      handleFileEdit({
        id: fileId,
        name: newData.name,
        created_at_epoch: newData.created_at_epoch,
        parent_folder_id: isActive?.id || null,
        portal_id: auth.user?.portal.id,
        access_type_id: auth.user?.portal.type_id,
        tags: newData.tags || null
      })
    }
  }

  const addFolder = (name: string) => {
    if (auth.user) {
      handleAddFolder({
        values: {
          name: name,
          portal_id: auth.user?.portal.id,
          access_type_id: 2,
          parent_folder_id: isActive?.id || null,
          created_at_epoch: Math.floor(Date.now() / 1000)
        }
      })
      setNewFolderModalOpen(false)
    }
  }

  const rename = (name: string) => {
    if (isSelected?.type !== 'folder') {
    } else {
      handleRenameFolder({ id: isSelected.id, name })
    }
    setRenameModalOpen(false)
  }

  const handleCopyLink = () => {
    navigator.clipboard.writeText(`${env.apiUrl}/image/get/${(isSelected as FileType).file_id}`)
  }

  const handleDelete = () => {
    setDeleteModalOpen(true)
  }

  const handleDownload = () => {
    readMediaFile({ file_id: (isSelected as FileType).file_id }).then(data => {
      if (typeof window !== 'undefined') {
        const url = URL.createObjectURL(new Blob([data.data]))
        const link = document.createElement('a')
        link.href = url
        link.download = isSelected?.name || ''
        link.style.display = 'none'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(url)
      }
    })
  }

  const handleDuplicate = () => {
    if (isSelected?.type !== 'folder' && auth.user) {
      handleDuplicateFile({
        ...(isSelected as FileType),
        portal_id: auth.user?.portal.id,
        access_type_id: auth.user?.portal.type_id,
        name: `${isSelected?.name} (${
          mediaList?.filter(
            media => media.type !== 'folder' && (media as FileType).file_id === (isSelected as FileType).file_id
          ).length
        })`,

        created_at_epoch: Date.now(),
        parent_folder_id: isActive?.id || null
      })
    }
  }

  const handleEdit = () => {
    setEditFileModalOpen(true)
  }

  const handleNewFolder = () => {
    setNewFolderModalOpen(true)
  }

  const handleRename = () => {
    setRenameModalOpen(true)
  }

  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    Array.from(e.target.files as FileList).forEach(file => handleMediaUpload(file))
  }

  const handleUploadThumbnail = (e: ChangeEvent<HTMLInputElement>) => {
    const thumbnail = e.target.files?.[0]
    const fileId = (isSelected as FileType).file_id
    if (thumbnail && fileId) {
      setIsThumbnail(true)
      handleMediaUpload(thumbnail, {
        onSuccess: data => {
          setIsThumbnail(false)
          addThumbnail({ preview_file_id: data.data.result.file_id, video_id: fileId })
        }
      })
    }
  }

  const modals = [
    <TextInputModal
      key={1}
      disableSubmitIfDirty
      submitText={t('ACTION.save')}
      isOpen={isRenameModalOpen}
      onSubmit={rename}
      onCancel={() => setRenameModalOpen(false)}
      defaultValue={isSelected?.name}
      title={`${t('ACTION.rename')} ${isSelected?.type}`}
    />,
    <TextInputModal
      key={2}
      submitText={t('extracted.create_folder')}
      isOpen={isNewFolderModalOpen}
      onSubmit={addFolder}
      onCancel={() => setNewFolderModalOpen(false)}
      defaultValue={isSelected?.name}
      title={t('extracted.create_new_folder')}
    />,
    <EditFileModal
      key={3}
      isOpen={isEditFileModalOpen}
      onSubmit={(newData: FileType) => {
        edit(newData)
        setEditFileModalOpen(false)
      }}
      onCancel={() => setEditFileModalOpen(false)}
      data={isSelected}
      mediaList={mediaList}
    />,
    <DeleteConfirmationModal
      key={4}
      isOpen={isDeleteModalOpen}
      entityType={''}
      confirmWord={'DELETE'}
      onClose={() => setDeleteModalOpen(false)}
      onDelete={deleteEntity}
    />
  ]

  return {
    mediaType,
    mediaList,
    mediaViewIsfetching,
    folderList,
    anchorEl,
    fileInputRef,
    thumbnailRef,
    mediaSortOrder,
    folderSortOrder,
    searchValue,
    isActive,
    isMentorCloudOpen,
    modals,
    setActive,
    setAnchorEl,
    handleCopyLink,
    handleDelete,
    handleDownload,
    handleDuplicate,
    handleEdit,
    handleNewFolder,
    handleRename,
    handleUpload,
    handleUploadThumbnail,
    setSearchValue,
    setMediaSortOrder,
    setFolderSortOrder,
    closeMentorCloud,
    openMentorCloud,
    fetchNextPage
  }
}
