export const cleanText = (html: string): string => {
  if (typeof window === 'undefined') return html

  console.log('cleantText')
  const tempDiv = document.createElement('div')
  tempDiv.innerHTML = html

  // Remove the Froala Editor caption
  const froalaCaption = tempDiv.querySelector('p[data-f-id="pbf"]')
  if (froalaCaption) {
    froalaCaption.remove()
  }

  // Remove any occurrences of "Powered by Froala Editor"
  tempDiv.childNodes.forEach(node => {
    if (node.nodeType === Node.TEXT_NODE && node.textContent?.includes('Powered by Froala Editor')) {
      node.remove()
    }
  })

  // Process all <p> tags
  tempDiv.querySelectorAll('p').forEach(p => {
    // Remove if the <p> contains only "Powered by Froala Editor"
    if (p.textContent?.trim() === 'Powered by Froala Editor') {
      p.remove()

      return
    }

    const images = Array.from(p.querySelectorAll('img'))

    if (images.length > 0) {
      images.forEach(img => {
        if (p && p.parentNode) {
          console.log('Checking nextElementSibling before inserting:', p.nextElementSibling)

          setTimeout(() => {
            // Ensure p.nextElementSibling is valid before inserting
            if (p && p.parentNode && p.nextElementSibling && p.parentNode.contains(p.nextElementSibling)) {
              p?.parentNode.insertBefore(img, p?.nextElementSibling)
            } else {
              console.warn('nextElementSibling is invalid, appending instead')
              p && p.parentNode && p.parentNode.appendChild(img)
            }
          }, 300)
        } else {
          console.error('Parent node is missing for:', p)
        }
      })
    }
  })

  return tempDiv.innerHTML
}
export const processImages = (html: string) => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  doc.querySelectorAll('img').forEach(img => {
    img.style.maxWidth = '100%'
    img.style.width = '100%'
    img.style.height = '300px'
    img.style.objectFit = 'cover'
    img.style.borderRadius = '6px'
  })

  return doc.body.innerHTML
}

export const adjustImagesToFullWidth = (html: string, alignCenter?: boolean) => {
  const parser = new DOMParser()

  const doc = parser.parseFromString(html, 'text/html')

  doc.querySelectorAll('img').forEach(img => {
    img.style.maxWidth = '100%'
    alignCenter && ((img.style.width = '100%'), (img.style.margin = 'auto'))
  })

  return doc.body.innerHTML
}
