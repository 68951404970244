import { Stack, List, styled, useTheme } from '@mui/material'
import { LoadingButton } from '@mui/lab'

import { useTranslation } from 'react-i18next'

import Icon from 'src/@core/components/icon'
import { LayoutProps, NavGroup } from 'src/@core/layouts/types'
import { useSettings } from 'src/@core/hooks/useSettings'
import VerticalNavItems from 'src/@core/layouts/components/vertical/navigation/VerticalNavItems'

import { useAuth } from 'src/hooks/useAuth'

import Can from 'src/layouts/components/acl/Can'
import { subjects } from 'src/navigation/vertical/subjects'

import { permissionActions } from 'src/configs/permissionActions'

import CustomVerticalNavItems from './CustomVerticalNavItems'

const ListStyled = styled(List, { shouldForwardProp: prop => prop !== 'flexDirection' })<{
  flexDirection?: 'row' | 'column'
  paddingX?: string
}>(({ flexDirection = 'column', paddingX }) => ({
  pt: 0,
  '& > :first-child': {
    mt: '0'
  },
  display: 'flex',
  flexDirection,
  justifyContent: 'space-between',
  paddingLeft: paddingX,
  paddingRight: paddingX
}))

const ButtonStyled = styled(LoadingButton)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 500
})

interface Props {
  parent?: NavGroup
  navHover?: boolean
  navVisible?: boolean
  groupActive: string[]
  isSubToSub?: NavGroup
  currentActiveGroup: string[]
  navigationBorderWidth: number
  settings: LayoutProps['settings']
  saveSettings: LayoutProps['saveSettings']
  setGroupActive: (value: string[]) => void
  setCurrentActiveGroup: (item: string[]) => void
  verticalNavItems?: LayoutProps['verticalLayoutProps']['navMenu']['navItems']
}

export const BottomNavMenu = (props: Props) => {
  const { t } = useTranslation()
  const { loginAsPreview, isLoginAsPreviewPending } = useAuth()

  const theme = useTheme()

  const {
    settings: { navCollapsed }
  } = useSettings()

  const flexDirection = navCollapsed && !props.navHover ? 'column' : 'row'

  return (
    <>
      <Can I={permissionActions.manage} a={subjects.ccPage}>
        <Stack spacing={theme.spacing(2)}>
          <Stack alignItems='center' justifyContent='center' padding={theme.spacing(2.5, 3.5)}>
            <ButtonStyled
              fullWidth
              variant='tonal'
              size='small'
              onClick={loginAsPreview}
              loading={isLoginAsPreviewPending}
              disabled={isLoginAsPreviewPending}
            >
              {navCollapsed && !props.navHover ? null : t('Preview')}

              <Icon icon='tabler:arrow-up-right' fontSize={16} />
            </ButtonStyled>
          </Stack>
          <ListStyled
            key={JSON.stringify(props.verticalNavItems)}
            className='nav-items'
            flexDirection={flexDirection}
            paddingX={navCollapsed && !props.navHover ? '0' : theme.spacing(3)}
          >
            <CustomVerticalNavItems {...props} />
          </ListStyled>
        </Stack>
      </Can>

      <Can I={permissionActions.manage} a={subjects.stundentPage}>
        <ListStyled key={JSON.stringify(props.verticalNavItems)} className='nav-items'>
          <VerticalNavItems {...props} />
        </ListStyled>
      </Can>

      <Can I={permissionActions.manage} a={subjects.rootPage}>
        <ListStyled key={JSON.stringify(props.verticalNavItems)} className='nav-items'>
          <VerticalNavItems {...props} />
        </ListStyled>
      </Can>
    </>
  )
}
