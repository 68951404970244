import { useQuery } from '@tanstack/react-query'

import queries from '../../queries'
import languageService from '../../service/language-service'

export const useLanguages = () => {
  return useQuery({
    ...queries.languages.all,
    queryFn: languageService.getLanguages,
    select: response => response.data.result
  })
}
