import { memo } from 'react'

import { Button, Dialog, DialogContent, Typography, useTheme } from '@mui/material'

import { useTranslation } from 'react-i18next'

import { useLanguages } from 'src/hooks/languages'

import { digistoreUrl } from 'src/utils/settings/portal-settings/utils'

import authService from 'src/service/auth-service'

type TrialEndedModalProps = {
  isOpen: boolean
  onClose: () => void
}

const TrialEndedModal = memo(({ isOpen, onClose }: TrialEndedModalProps) => {
  const theme = useTheme()
  const { t } = useTranslation('translation', { keyPrefix: 'TRIAL_INFO' })
  const user = authService.getUser()
  const { data: languages = [] } = useLanguages()
  const selectedLang = languages.find(lang => lang.id === user?.portal.language_id)?.code
  const firstName = user?.portal_member.first_name
  const lastName = user?.portal_member.last_name
  const email = user?.portal_member.email
  const url = digistoreUrl(email!, firstName!, lastName!, selectedLang!)

  const openUrl = () => {
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth='sm'
      fullWidth
      PaperProps={{
        sx: { overflow: 'visible', position: 'relative' }
      }}
    >
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 4 }}>
        <Typography variant='h4' textAlign={'center'}>
          {t('trial_ended_title')}
        </Typography>
        <Typography variant='body1' textAlign={'center'}>
          {t('trial_ended_description')}
        </Typography>
        <Button variant='contained' sx={{ boxShadow: theme.shadow.primary.sm }} onClick={openUrl}>
          {t('trial_ended_button')}
        </Button>
      </DialogContent>
    </Dialog>
  )
})

export default TrialEndedModal
