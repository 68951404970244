import { useMemo, useState } from 'react'

import { Avatar, Box, Stack, styled, useTheme, Theme } from '@mui/material'
import IconButton from '@mui/material/IconButton'

import DOMPurify from 'dompurify'
import { useTranslation } from 'react-i18next'

import { usePortalSettings } from 'src/hooks/usePortalSettings'

import { VideoPlayer } from 'src/components/common/VideoPlayer'

import { cleanText } from 'src/utils/html'
import { getImageUrl } from 'src/utils/getImageUrl'

import authService from 'src/service/auth-service'

import Icon from '../../../@core/components/icon'
import useModal from '../../../hooks/useModal'

const ShoutoutBoxWrapper = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(6),
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  gap: theme.spacing(4),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    gap: theme.spacing(2)
  }
}))

const StyledAvatar = styled(Avatar)(
  ({ theme, loaded, hasImage }: { theme: Theme; loaded: boolean; hasImage: boolean }) => ({
    width: 100,
    height: 100,
    alignSelf: 'flex-start',
    ...(hasImage && {
      backgroundColor: loaded ? 'transparent' : theme.palette.divider,
      filter: loaded ? 'none' : 'blur(3px)',
      transition: 'filter 0.3s ease-in-out'
    }),
    [theme.breakpoints.down('md')]: {
      alignSelf: 'center'
    }
  })
)

const HiddenImage = styled('img')({
  display: 'none' // This is used only for triggering onLoad
})

const TextContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  maxWidth: 767,
  width: '100%',
  padding: theme.spacing(6),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: `0 4px 18px 0 ${theme.colorWithOpacity('#4B465C', 10)}`
}))

const HideBoxButton = styled(IconButton)({
  position: 'absolute',
  top: 0,
  right: 0
})

export const ShoutoutBox = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { getUser } = authService

  const { portalSettings } = usePortalSettings()
  const [loaded, setLoaded] = useState(false)

  const shoutoutBox = useMemo(
    () => portalSettings?.content_homepage_settings?.[0],
    [portalSettings?.content_homepage_settings]
  )
  const { isOpen, close } = useModal(true)

  const sanitizedText = useMemo(() => {
    const rawText = cleanText(shoutoutBox?.shoutout_box_text || '')

    const sanitized = DOMPurify.sanitize(rawText || '', { ADD_ATTR: ['target', 'rel'] })

    // Modify all links to open in a new tab safely
    const parser = new DOMParser()
    const doc = parser.parseFromString(sanitized, 'text/html')

    doc.querySelectorAll('a').forEach(link => {
      link.setAttribute('target', '_blank')
      link.setAttribute('rel', 'noopener noreferrer')
    })

    return doc.body.innerHTML
  }, [shoutoutBox?.shoutout_box_text])

  const initials = useMemo(() => {
    const user = getUser()

    return `${user?.portal_member.first_name?.[0] || ''}${user?.portal_member.last_name?.[0] || ''}`
  }, [getUser])

  const imageUrl =
    portalSettings?.content_homepage_settings?.[0]?.shoutout_box_image ||
    portalSettings?.design_settings?.properties?.default_shoutout_box_image ||
    ''

  if (!shoutoutBox?.show_shoutout_box) {
    return null
  }

  const handleSetLoaded = () => setLoaded(true)

  if (!isOpen) return null

  return (
    <ShoutoutBoxWrapper>
      <HiddenImage
        src={imageUrl ? getImageUrl(imageUrl) : ''}
        onLoad={handleSetLoaded}
        onError={handleSetLoaded}
        alt={t('extracted.preload_avatar')}
      />

      <StyledAvatar
        theme={theme}
        loaded={loaded}
        hasImage={!!imageUrl}
        src={imageUrl ? getImageUrl(imageUrl) : ''}
        alt={t('extracted.user_avatar')}
      >
        {initials}
      </StyledAvatar>

      <TextContainer display='flex' flexDirection='column' gap={4}>
        {shoutoutBox.allow_hide_shoutout_box && (
          <HideBoxButton onClick={close}>
            <Icon icon='tabler:x' color={theme.palette.text.primary} />
          </HideBoxButton>
        )}
        <Box dangerouslySetInnerHTML={{ __html: sanitizedText }} />

        <VideoPlayer
          maxWidth={767}
          height={300}
          fileId={shoutoutBox?.video_files?.[0]?.file_id}
          link={shoutoutBox?.video_files?.[0]?.link}
        />
      </TextContainer>
    </ShoutoutBoxWrapper>
  )
}
