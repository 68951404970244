import { useMemo } from 'react'

import { useRouter } from 'next/router'

import { Box, Link, Stack, styled, Theme, Typography, useTheme } from '@mui/material'

import { useTranslation } from 'react-i18next'
import DOMPurify from 'dompurify'

import { usePortalSettings } from 'src/hooks/usePortalSettings'

import { getGDPRLinks } from 'src/utils/settings/portal-settings/utils'
import { getImageUrl } from 'src/utils/getImageUrl'
import { cleanText } from 'src/utils/html'

import { supportTypeIDs } from '../../../types/settings'
import { EMPTY_FOOTER_TAG } from '../../design/look-and-feel/constants'

import { ContactForm } from './ContactForm'

const FooterWrapper = styled(Box)(
  ({ theme, footerBgColor, backgroundImage }: { theme: Theme; footerBgColor: string; backgroundImage?: string }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(8),
    marginTop: 10,
    padding: theme.spacing(6),
    backgroundColor: footerBgColor || theme.palette.background.paper,
    ...(backgroundImage && {
      backgroundImage: `url(${backgroundImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundBlendMode: 'overlay'
    })
  })
)

const SubFooterWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'center',
  flexWrap: 'wrap',
  gap: theme.spacing(6),
  alignItems: 'center',
  padding: theme.spacing(4, 6),
  backgroundColor: theme.palette.customColors.navbarBg
}))

const ImageContainer = styled(Box)(({ theme, imageUrl }: { theme: Theme; imageUrl: string }) => ({
  position: 'absolute',
  width: 164,
  height: 164,
  top: -120,
  right: 64,
  borderRadius: '50%',
  zIndex: 100,
  ...(imageUrl && {
    background: `url(${imageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  }),
  [theme.breakpoints.down('sm')]: {
    top: -145
  }
}))

const StyledTypography = styled(Typography)(
  ({
    theme,
    footerTextColor,
    showAdditionalImage
  }: {
    theme: Theme
    footerTextColor: string
    showAdditionalImage: boolean
  }) => ({
    color: footerTextColor || theme.palette.text.primary,
    maxWidth: showAdditionalImage ? '80%' : 'auto',
    ...(showAdditionalImage && {
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%'
      }
    })
  })
)

export const Footer = () => {
  const { t: tGlobal } = useTranslation('translation')

  const router = useRouter()

  const theme = useTheme()

  const { portalSettings } = usePortalSettings()

  const [gdpr, footerSettings, designSettings] = useMemo(
    () => [
      portalSettings?.settings_imprint_gdrp?.[0],
      portalSettings?.general_design_settings?.[0].footer_settings[0],
      portalSettings?.design_settings.properties
    ],
    [portalSettings]
  )

  const isContactFormActive = useMemo(() => {
    if (!portalSettings?.support_settings) return false

    return (
      portalSettings.support_settings?.find(support => support?.is_active)?.support_type_id ===
      supportTypeIDs.contact_form
    )
  }, [portalSettings?.support_settings])

  const iconImageUrl = footerSettings?.additional_image || designSettings?.footer_additional_image || ''
  const bgImageUrl = footerSettings?.background_image || designSettings?.footer_bg_image || ''

  const footer = useMemo(() => {
    const hasImage = footerSettings?.show_additional_image && iconImageUrl
    const hasBgImage = footerSettings?.background_image && bgImageUrl
    const hasFooterContent =
      footerSettings?.footer_headline ||
      (footerSettings?.footer_desc && footerSettings?.footer_desc !== EMPTY_FOOTER_TAG)
    const canDisplayFooter = footerSettings?.show_footer_block && (hasImage || hasFooterContent || hasBgImage)

    return (
      canDisplayFooter && (
        <FooterWrapper
          theme={theme}
          footerBgColor={footerSettings?.background_color || designSettings?.footer_bg_color || ''}
          backgroundImage={getImageUrl(bgImageUrl)}
        >
          {hasImage && <ImageContainer theme={theme} imageUrl={getImageUrl(iconImageUrl)} />}
          {hasFooterContent && (
            <Stack gap={theme.spacing(6)}>
              {footerSettings.footer_headline && (
                <StyledTypography
                  variant='h4'
                  theme={theme}
                  footerTextColor={footerSettings.footer_text_color}
                  showAdditionalImage={footerSettings.show_additional_image}
                >
                  {footerSettings.footer_headline}
                </StyledTypography>
              )}

              {footerSettings?.footer_desc && (
                <StyledTypography
                  variant='body1'
                  theme={theme}
                  footerTextColor={footerSettings.footer_text_color}
                  showAdditionalImage={footerSettings.show_additional_image}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(cleanText(footerSettings?.footer_desc || '') || '')
                  }}
                />
              )}
            </Stack>
          )}
        </FooterWrapper>
      )
    )
  }, [bgImageUrl, designSettings?.footer_bg_color, footerSettings, iconImageUrl, theme])

  return (
    <footer>
      {footer}
      <SubFooterWrapper>
        {footerSettings?.custom_links?.[0] &&
          footerSettings.custom_links &&
          footerSettings.custom_links.map(linkItem => (
            <Link
              key={linkItem.id}
              href={linkItem?.link}
              variant='h6'
              target={linkItem.open_new_tab ? '_blank' : '_self'}
              rel='noopener noreferrer'
              color={theme.palette.customColors.navbarText}
              sx={{
                textDecoration: 'none',
                fontWeight: 'normal'
              }}
            >
              {linkItem?.name}
            </Link>
          ))}

        {isContactFormActive && <ContactForm />}
        {getGDPRLinks(gdpr?.show_terms_page, router.basePath, true).map(({ title, url }) => (
          <Typography
            key={title}
            component={'a'}
            href={url}
            variant='body1'
            sx={{ textDecoration: 'none' }}
            color={theme.palette.customColors.navbarText}
          >
            {tGlobal(title)}
          </Typography>
        ))}
      </SubFooterWrapper>
    </footer>
  )
}
